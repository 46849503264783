import React, { useEffect, useState, useRef, memo } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { toast } from "react-toastify";
import { default as SuppliesService } from 'services/Supplies';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	&.modal {

		.modal-title {
			text-transform: uppercase;
		}

		label {
			font-size: 15px;
			line-height: 15px;
		}

		small {
			font-size: 12px;
			line-height: 12px;
			color: var(--plenoil-blue-light);
		}
	}

	#table-periods {
		td {
			vertical-align: top;
		}
	}
`;

let axiosCancelToken = null;

const ConfigModal = memo((props) => {
	const modalRef = useRef(null);

	const type = props.type;
	const station = props.station;
	const closeCallback = props.closeCallback;

	const [data, setData] = useState({
		enabled: false,
	});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		const getData = async () => {
			setErrors({});
			setLoading(true);

			let result = null;
			if ( station ) {
				result = await SuppliesService.getPlanningStationConfig(type, station.id);
			} else {
				result = await SuppliesService.getPlanningGlobalConfig(type);
			}
			setData(result);

			setLoading(false);
		}
		getData();
	}, [station, type]);

	const closeModal = () => {
		// Hide modal
		Modal.getInstance(modalRef.current).hide();
		props.closeCallback();
	}

	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const saveData = async (recalculate = false) => {
		setErrors({});
		setLoading(true);

		let result = null;
		if ( station ) {
			result = await SuppliesService.setPlanningStationConfig(type, station.id, data, recalculate);
		} else {
			result = await SuppliesService.setPlanningGlobalConfig(type, data, recalculate);
		}
		if ( !result.status ) {
			toast.error('Error al guardar la configuración');
		}

		if ( recalculate ) {
			// TODO
		}
		
		setLoading(false);

		closeModal();
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">planificación <u>{station ? station.station_name : 'general'}</u></h5>
						<button type="button" className="btn-close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								<div className="mb-3">
									<label>DÍAS</label>
									<input type="text" className="form-control form-control-sm" value={data?.days ?? ''} onChange={(e) => setDataField('days', e.target.value)} disabled={loading} />
									<small>Número de días entre periodos</small>
									{ errors?.days &&
										<div className="invalid-feedback d-block">{ errors['days'][0] ?? '' }</div>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button className="btn btn-sm btn-secondary" disabled={loading} onClick={() => loading ? null : saveData(true)}>{loading ? 'Guardando...' : 'Guardar y recalcular'}</button>
						<button className="btn btn-sm btn-success" disabled={loading} onClick={() => loading ? null : saveData()}>{loading ? 'Guardando...' : 'Guardar y salir'}</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}, (a, b) => {
	return true;
});

export default ConfigModal;