import React, { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import moment from 'moment';
import { Tooltip } from 'bootstrap';
import { default as PricesService } from 'services/Prices';
import { toast } from 'react-toastify';

const GlobalStyle = createGlobalStyle`
    .tooltip-inner {
        max-width: unset !important;
    }

    #tooltip-competition {
        text-align: left;
        font-size: 15px;
        line-height: 100%;
        padding: 8px;
        width: 250px;

        h1 {
            display: block;
            font-weight: bold;
            font-size: 15px;
            line-height: 15px;
            margin: 0;
        }

        small {
            display: block;
            font-size: 11px;
            line-height: 11px;

            &.tbl-row {
                display: flex;
                justify-content: space-between;
            }
        }

        b {
            font-weight: 500;
        }
    }
`;

const CompetitionPricesFrameStyled = styled.div` 
    display: flex;
    align-items: center;
    width: 145px;
    height: 50px;

    .initial {
        position: relative;
        width: 25px;
        min-width: 25px;
        max-width: 25px;
        height: 100%;
        cursor: pointer;
        user-select: none;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            font-size: 12px;
            background: var(--bs-gray-400);
            color: white;
            border-radius: 50%;

            .photo-preview {
                position: absolute;
                cursor: default;

                background: white;
                top: 80%;
                left: 5px;
                padding: 10px;
                border-radius: 5px;
                border: 3px solid var(--bs-gray-300);
                z-index: 9999;

                img {
                    max-width: 200px;
                    cursor: pointer;
                }
            }
        }

        .inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;
            height: 100%;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .inner-top,
            .inner-bottom {
                width: 100%;
                height: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .prices-rows-container {
        width: 100%;
        border-left: 1px solid #dee2e6;

        i {
            font-size: 10px;
            margin-right: 5px;
        }

        div {
            padding: 0;
        }
    }
`;

const PricesRowsContainer = styled.div` 
	display: flex;
	flex-direction: column;
	font-size: 14px;
	line-height: 16px;
    height: 100%;

    ${props => props.app ? 'background: #ff7a002b;' : ''}

	span {
        position: relative;
		display: block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin-right: 5px;

		&.goa {
			background: var(--plenoil-black);
		}

		&.sp95 {
			background: var(--plenoil-green);
		}
	}

	div {
		display: flex;
		align-items: center;
		padding: 0 10px;
        height: 50%;

		&:first-of-type {
			border-bottom: 1px solid #dee2e6;
		}
	}

	i {
		width: 12px;
	}

	u {
		color: var(--bs-gray-500);
		font-size: 9px;
		letter-spacing: -1px;
		margin-left: auto;
		text-decoration: none;
		padding-right: 5px;
	}

    input {
        width: 65px;
        background: transparent;
        padding: 0;
        margin: 0;
        border: 0;
    }
`;


export default function CompetitionPricesFrame(props) {
    let ref = useRef(null);

    let competitionPrices = props.competition; // Minetur
    let currentPrices = props.current; // Actual prices from dyngas webservice
    let appPrices = props.app; // Prices from app when expenders upload photos

    let [showAppPrices, setShowAppPrices] = useState(false);
    let [showPhoto, setShowPhoto] = useState(false);
    let [photo, setPhoto] = useState(null);

    // competitionPrices.goa_price = 0;
    // competitionPrices.goa_price = 0;

    // Minetur
        let goaMineturPriceHigh = parseFloat(competitionPrices?.goa_price) < parseFloat(currentPrices?.goa_price);
        let goaMineturPriceLow = parseFloat(competitionPrices?.goa_price) > parseFloat(currentPrices?.goa_price);
        let goaMineturDifference = parseFloat(competitionPrices?.goa_price) - parseFloat(currentPrices?.goa_price);
        
        let sp95MineturPriceHigh = parseFloat(competitionPrices?.sp95_price) < parseFloat(currentPrices?.sp95_price);
        let sp95MineturPriceLow = parseFloat(competitionPrices?.sp95_price) > parseFloat(currentPrices?.sp95_price);
        let sp95MineturDifference = parseFloat(competitionPrices?.sp95_price) - parseFloat(currentPrices?.sp95_price);

    // App
        let mineturGoaDifferentFromAppLow = appPrices?.goa_price && parseFloat(competitionPrices?.goa_price) > parseFloat(appPrices?.goa_price);
        let mineturGoaDifferentFromAppHigh = appPrices?.goa_price && parseFloat(competitionPrices?.goa_price) < parseFloat(appPrices?.goa_price);
        let mineturSp95DifferentFromAppLow = appPrices?.sp95_price && parseFloat(competitionPrices?.sp95_price) > parseFloat(appPrices?.sp95_price);
        let mineturSp95DifferentFromAppHigh = appPrices?.sp95_price && parseFloat(competitionPrices?.sp95_price) < parseFloat(appPrices?.sp95_price);

    const toggleShowAppPrices = (e) => {
        e.stopPropagation();

        setShowAppPrices(!showAppPrices);
    }

    const togglePhoto = (e, force = false) => {
        e.stopPropagation();

        if (force) {
            setShowPhoto(force);
            return;
        }

        if (e.type === 'click' && showPhoto) {
            setShowPhoto(false);
            return;
        }

        setShowPhoto(!showPhoto);
    }

    const openPhoto = (photo) => {
        window.open(photo);
    }

    const updateAppPrice = async (type, price) => {
        if ( !appPrices?.visit_id ) return;

        document.activeElement.blur(); // Blur input

        let result = await PricesService.saveStationCompetitionVisitPrice(appPrices.visit_id, type, price);
        if ( !result.status ) toast.error('Error al actualizar el precio real');
        else {
            toast.success('Precio actualizado');
            if ( props.reloadParent ) props.reloadParent();
        }
    }

    useEffect(() => {
        let tooltip = new Tooltip(ref.current.querySelector('[data-bs-toggle="tooltip"]'));

		return function cleanup() {
            tooltip.dispose();
		}
	}, [competitionPrices, currentPrices, appPrices]);

    useEffect(() => {
        if ( photo || !showPhoto ) return;

        const getPhoto = async () => {
            let result = await PricesService.getStationCompetitionVisitPhoto(appPrices?.visit_id);
            
            let objectUrl = URL.createObjectURL(result);
            setPhoto(objectUrl);
        }
        getPhoto();
    }, [showPhoto, photo, appPrices?.visit_id]);

    return (
        <CompetitionPricesFrameStyled className="competition-frame" ref={ref} onClick={(e) => e.stopPropagation()}>
            <GlobalStyle />
            <div 
                className={'initial'}
                onClick={(e) => toggleShowAppPrices(e)}
            >   
                <div className="inner">
                    <div className={'inner-top ' + (mineturGoaDifferentFromAppLow ? 'bg-plenoil-green' : '') + ' ' + (mineturGoaDifferentFromAppHigh ? 'bg-plenoil-orange' : '')}>
                        <span 
                            data-bs-toggle="tooltip" 
                            data-bs-placement="top" 
                            title={`
                                <div id="tooltip-competition">
                                    <h1>`+(competitionPrices?.name ?? '')+`</h1>
                                    <small class="mt-1">`+(competitionPrices?.address ?? '')+`</small>
                                    <hr class="my-2" />
                                    <small class="tbl-row">
                                        <b>Hora minetur GOA</b> `+(competitionPrices?.goa_date ? moment(competitionPrices.goa_date).format('DD-MM-YYYY HH:mm') : '-')+`
                                    </small>
                                    <small class="tbl-row mt-1">
                                        <b>Hora minetur SP95</b> `+(competitionPrices?.sp95_date ? moment(competitionPrices.sp95_date).format('DD-MM-YYYY HH:mm') : '-')+`
                                    </small>
                                    <small class="tbl-row mt-1">
                                        <b>Hora expendedor</b> `+(appPrices?.date ? moment(appPrices.date).format('DD-MM-YYYY HH:mm') : '-')+`
                                    </small>
                                </div>
                            `}
                            data-bs-html="true"
                        >
                            {competitionPrices?.name?.substring(0, 1)}
                        </span>
                    </div>
                    <div className={'inner-bottom ' + (mineturSp95DifferentFromAppLow ? 'bg-plenoil-green' : '') + ' ' + (mineturSp95DifferentFromAppHigh ? 'bg-plenoil-orange' : '')}>
                        {appPrices?.has_photo &&
                            <span 
                                className="bg-plenoil-primary text-plenoil-yellow" 
                                onClick={(e) => togglePhoto(e)} 
                                onMouseEnter={(e) => togglePhoto(e)} 
                                onMouseLeave={(e) => togglePhoto(e, false)}
                            >
                                <i className="bi bi-camera"></i>

                                { showPhoto &&
                                    <div className="photo-preview">
                                        <img src={photo} onClick={() => openPhoto(photo)} alt="phto" />
                                    </div>
                                }
                            </span>
                        }
                    </div>
                </div>
            </div>
            <PricesRowsContainer className="prices-rows-container" app={showAppPrices}>
                <div 
                    className={
                        (showAppPrices && mineturGoaDifferentFromAppHigh ? ' bg-plenoil-orange text-white' : '') +
                        (goaMineturPriceHigh ? 'bg-danger text-plenoil-yellow' : '') +
                        (showAppPrices && mineturGoaDifferentFromAppLow ? ' bg-plenoil-green text-white' : '')
                    }
                >
                    {goaMineturPriceHigh && <i className="bi bi-caret-down-fill text-white"></i>}
                    {goaMineturPriceLow && <i className="bi bi-caret-up-fill text-plenoil-orange"></i>}
                    {(!goaMineturPriceHigh && !goaMineturPriceLow) && <i className="bi"></i>}

                    {!showAppPrices ? (competitionPrices?.goa_price ?? '') : ''}
                    {showAppPrices ? 
                        <input 
                            type="number" 
                            className={'no-arrows ' + (mineturGoaDifferentFromAppLow || mineturGoaDifferentFromAppHigh ? 'text-white' : '')} 
                            step="0.001" 
                            defaultValue={appPrices?.goa_price ?? ''} 
                            onBlur={(e) => updateAppPrice('goa', e.target.value)}
                            onKeyPress={(e) => e.code === 'Enter' ? document.activeElement.blur() : null}
                            disabled={!appPrices?.visit_id}
                        /> 
                        : 
                        ''
                    }

                    <u>{goaMineturDifference ? '{ ' + goaMineturDifference.toFixed(3) + ' }' : ''}</u>
                </div>
                <div 
                    className={
                        (showAppPrices && mineturSp95DifferentFromAppHigh ? ' bg-plenoil-orange text-white' : '') +
                        (sp95MineturPriceHigh ? 'bg-danger text-plenoil-yellow' : '') + 
                        (showAppPrices && mineturSp95DifferentFromAppLow ? ' bg-plenoil-green text-white' : '')
                    }
                >
                    {sp95MineturPriceHigh && <i className="bi bi-caret-down-fill text-white"></i>}
                    {sp95MineturPriceLow && <i className="bi bi-caret-up-fill text-plenoil-orange"></i>}
                    {(!sp95MineturPriceHigh && !sp95MineturPriceLow) && <i className="bi"></i>}

                    {!showAppPrices ? (competitionPrices?.sp95_price ?? '') : ''}
                    {showAppPrices ? 
                        <input 
                            type="number" 
                            className={'no-arrows ' + (mineturSp95DifferentFromAppLow || mineturSp95DifferentFromAppHigh ? 'text-white' : '')} 
                            step="0.001" 
                            defaultValue={appPrices?.sp95_price ?? ''} 
                            onBlur={(e) => updateAppPrice('sp95', e.target.value)}
                            onKeyPress={(e) => e.code === 'Enter' ? document.activeElement.blur() : null}
                            disabled={!appPrices?.visit_id}
                        /> 
                        : 
                        ''
                    }

                    <u>{sp95MineturDifference ? '{ ' + sp95MineturDifference.toFixed(3) + ' }' : ''}</u>
                </div>
            </PricesRowsContainer>
        </CompetitionPricesFrameStyled>
    )
};