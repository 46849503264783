import LoggedLayout from 'layouts/LoggedLayout';

export default function Dashboard() {
	
	return (
		<LoggedLayout>
			Dashboard
		</LoggedLayout>
	);
}


