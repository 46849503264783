import { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import LoggedLayout from 'layouts/LoggedLayout';
import axios from 'axios';
import styled from 'styled-components';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import { hasCapability } from 'helpers/user';

const Filters = styled.div` 
	align-items: center;

	input[type=search] {
		height: auto;
		min-height: 0;
	}
`;

const Table = styled.table`
	th,
	td {
		&:nth-child(1),
		&:nth-child(2) {
			width: 150px;
			vertical-align: middle;
		}

		&:nth-child(4) {
			width: 40px;
			vertical-align: middle;
			text-align: center;
		}
	}
`;

let axiosCancelToken = null;
let searchTimeout = null;

export default function Users() {
	const [queryParams] = useSearchParams();

	let [users, setUsers] = useState([]);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('name');
	let [search, _setSearch] = useState(null);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setUsers({...users, data: undefined});
		_setPage(page);
	}
	const setSearch = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			_setSearch(value);
		}, 500);
	}
 
	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const getUsers = async () => {
			return axios.get('api/users/list', {
				params: {
					page: page,
					sort: sortField,
					direction: sortDirection,
					search: search,
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setUsers({...response.data});
		    	setSkeletonRows(response.data.data.length);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}

		getUsers();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [page, sortDirection, sortField, search]);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-primary text-white text-uppercase p-2">
					<Filters className="row">
						<div className="col-md-2">
							Listado de usuarios
						</div>
						<div className="col-md-3">
							<input type="search" placeholder="Buscar" className="form-control form-control-sm" onChange={(e) => setSearch(e.target.value)} />
						</div>
						<div className="col-md-3">

						</div>
						<div className="col-md-2">

						</div>
						<div className="col-md-2">
							{ hasCapability('users', 'add') &&
								<NavLink to="/users/add" className="btn btn-sm btn-light p-0 px-2 float-end">Añadir</NavLink>
							}
						</div>
					</Filters>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<div className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Nombre</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'email'} onClick={() => sortTableClick('email')}>Email</ThSortable>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ users.data?.map((el, idx) => {
								return (
									<tr key={idx}>
										<td>{el.name}</td>
										<td>{el.email}</td>
										<td></td>
										<td>
											<NavLink to={'/users/edit/' + el.id}><i className="bi bi-pencil text-plenoil-primary"></i></NavLink>
										</td>
									</tr>
								)
							}) }

							{ users.data && !users.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ users.data === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
						</tbody>
					</Table>
				</div>
			</div>

			<div className="col-md-12 text-end">
				<div className="d-inline-block">
					<Paginator
						min={1}
						current={users?.current_page}
						max={users?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</div>
			</div>
		</LoggedLayout>
	);
}


