import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import EmpoweredSelector from 'components/EmpoweredSelector';
import Tank from 'components/Tank';
import { hasCapability } from 'helpers/user';
import { loader } from 'helpers/generic';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	#loader {
		margin-left: 5px;
		height: 20px;
	}
`;

let axiosCancelToken = null;

export default React.memo(function DeviationModal(props) {
	const modalRef = useRef(null);

	const parent = props.parent;
	const closeCallback = props.closeCallback;

	const [data, setData] = useState({
		station: null,
	});
	const [probeData, setProbeData] = useState(null);
	const [probeLoading, setProbeLoading] = useState(false);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);


	const saveData = async () => {
		setErrors({});

		data.manual = true; // Set manual on DB
		data.parent_id = parent.id; // Set parent id

		await axios.post('api/tankertrucks/add', data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Desvio realizado');
			
			// Hide modal
			const modal = Modal.getInstance(modalRef.current);
			modal.hide();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const loadStations = (input, callback) => {
		axios.get('/api/stations/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});

			callback(formatted);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectStation = (value) => {
		setData({...data, station: value, id: value?.id});
		setProbeData(null);
	}

	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const getProbeData = async () => {
		setProbeData(null);
		setProbeLoading(true);
		await axios.get('api/probes/get/' + data.station_id, {
			params: {},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setProbeData(response.data);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});
		setProbeLoading(false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Desvío de cisterna</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="mb-2">
							<div className="d-flex justify-content-between">
								<label>Estación de destino</label>
								{ (data.station?.id && hasCapability('probes')) &&
									<button 
										className={'btn btn-sm btn-plenoil-blue-light p-0 px-1 mb-1'} 
										disabled={probeLoading} 
										onClick={getProbeData}
									>
										PEDIR SONDA
										{ probeLoading && loader }
									</button>
								}
							</div>
							<EmpoweredSelector
								load={(input, callback) => loadStations(input, callback)}
								onChange={(value, text) => selectStation(value)}
								timeout={200}
								label={data.station?.name}
								value={data.station?.id ?? null}
							/>
							{ errors.station_id &&
								<div className="invalid-feedback d-block">{ errors.station_id[0] }</div>
							}
						</div>

						{ probeData &&
							<React.Fragment>
								<div className="d-flex w-100 py-2">
									<Tank
										className="w-50 pe-1"
										type="diesel"
										percent={probeData.diesel_percent}
										available={probeData.diesel_available}
										width={100}
									/>
									<Tank
										className="w-50 ps-1"
										type="splomo"
										percent={probeData.splomo_percent}
										available={probeData.splomo_available}
										width={100}
									/>
								</div>
								{ probeData.error &&
									<div className="invalid-feedback d-block mb-3"><b>Error al pedir la sonda:</b> {probeData.error}</div>
								}
							</React.Fragment>
						}

						<div className="mb-2">
							<label className="d-flex justify-content-between">
								Litros diesel
								<small>Disponible: {parent.diesel_liters_final} litros</small>
							</label>
							<input type="number" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('diesel_liters', e.target.value)} value={data.diesel_liters ?? ''} />
							{ errors.diesel_liters &&
								<div className="invalid-feedback d-block">{ errors.diesel_liters[0] }</div>
							}
						</div>

						<div className="mb-2">
							<label className="d-flex justify-content-between">
								Litros gasolina
								<small>Disponible: {parent.sp95_liters_final} litros</small>
							</label>
							<input type="number" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('sp95_liters', e.target.value)} value={data.sp95_liters ?? ''} />
							{ errors.sp95_liters &&
								<div className="invalid-feedback d-block">{ errors.sp95_liters[0] }</div>
							}
						</div>

						<div className="mb-2">
							<div className="mb-2">
								<label>Día</label>
								<input type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ?? ''} />
								{ errors.date &&
									<div className="invalid-feedback d-block">{ errors.date[0] }</div>
								}
							</div>
						</div>

						<div className="row">
							<div className="col-md 6">
								<div className="mb-2">
									<label>Hora llegada inicio</label>
									<input type="time" className="form-control form-control-sm" onChange={(e) => setDataField('hour_expected_init', e.target.value)} value={data.hour_expected_init ?? ''} />
									{ errors.hour_expected_init &&
										<div className="invalid-feedback d-block">{ errors.hour_expected_init[0] }</div>
									}
								</div>
							</div>
							<div className="col-md 6">
								<div className="mb-2">
									<label>Hora llegada fin</label>
									<input type="time" className="form-control form-control-sm" onChange={(e) => setDataField('hour_expected_end', e.target.value)} value={data.hour_expected_end ?? ''} />
									{ errors.hour_expected_end &&
										<div className="invalid-feedback d-block">{ errors.hour_expected_end[0] }</div>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-plenoil-primary text-white'} onClick={() => saveData()}>Realizar desvío</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}, (a, b) => {
	return true;
});