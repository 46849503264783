import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { getUser, hasCapability } from 'helpers/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import moment from 'moment';
import { assets } from 'helpers/generic';
import ProbesWarningGlobe from "components/ProbesWarningsGlobe";

const Navbar = styled.nav`
	background: white;
	padding: 0;
	border-bottom: 1px solid lightgray;

	.navbar-brand {
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;

		@media (max-width: 768px) {
			padding: 10px 10px;
		}

		@media (min-width: 768px) {
			width: 230px;
			align-items: center;

			img {
				width: 140px;
			}
		}
	}

	.navbar-toggler {
		outline: none !important;
		box-shadow: none !important;
	}

	.navbar-nav {
		width: 100%;
	}	

	.navbar-collapse {
		border-left: 1px solid lightgray;
		padding: 0;
	}

	#user {
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 12px;
		line-height: 12px;
		margin-top: 5px;

		small {
			color: gray;
		}

		span {
			color: #333;
			text-transform: uppercase;
			margin-bottom: 2px;
			border-bottom: 1px solid gray;
		}

		a {
			font-size: 10px;
			color: var(--plenoil-blue-light);
			text-decoration: none;
		}

		@media (max-width: 992px) {
			display: block !important;
			text-align: center;
			border-bottom: 1px solid lightgray;

			small {
				display: block;
			}

			a {
				display: block;
				margin: 0 auto !important;
				margin-top: 5px !important;
				margin-bottom: 0 !important;
			}
		}
	}		

	#clock {
		position: relative;
		display: flex;
		height: 100%;
		justify-content: center;
		align-items: center;
		padding-right: 10px;
		border-right: 1px solid lightgray;
		color: gray;

		i {
			font-size: 12px;
			margin-right: 5px;
		}

		span {
			font-size: 30px;
			font-weight: lighter;
		}

		@media (max-width: 992px) {
			border-right: 0;
			border-bottom: 1px solid lightgray;
		}
	}
`;

const Content = styled.section`
	margin-top: 70px;
	padding: 0 10px;

	@media (min-width: 768px) {
		margin-left: 230px;
	}
`;

const Sidebar = styled.div`
	--top: ${props => props.top}px;

	position: fixed;
	top: var(--top);
	left: ${props => props.opened ? 0 : -100}%;
	z-index: 20;
	overflow-y: auto;
	width: 230px;
	height: calc(100% - var(--top));
	padding-top: 20px;
	background: white;
	box-shadow: rgb(1 41 112 / 10%) 0px 0px 10px;

	@media (min-width: 768px) {
		left: 0;
	    box-shadow: none;
    	transition: all 0.3s;
	}
`;

const SidebarTitle = styled.div`
	display: flex;
	align-items: center;
	font-size: 25px;
	color: var(--plenoil-gray);
	border-bottom: 1px solid transparent;
	border-bottom-color: ${props => props.borderColor ?? ''};
	border-bottom-width: ${props => props.selected ? 8 : 2}px;
	padding-left: 40px;
	text-transform: uppercase;
	margin-bottom: 15px;

	a {
		color: unset;
		text-decoration: none;
	}

	&:not(:first-of-type) {
		margin-top: 50px;
	}

	i {
		color: var(--plenoil-blue);
	}
`;

const SidebarItem = styled.div`
	font-weight: 500;
	text-transform: uppercase;
	margin-left: 45px;
	font-size: 14px;

	a {
		color: unset;
		text-decoration: none;

		&.active {
			color: var(--plenoil-blue);
			font-weight: bold;
		}
	}
`;


let lastSidebarTop = 0;
let timeInterval = 0;

export default function AppLayout(props) {
	let location = useLocation();

	const navbarRef = useRef(null);

	const [navbarCollapse, setNavbarCollapse] = useState(true);
	const [sidebarTop, setSidebarTop] = useState(lastSidebarTop);

	// Reposition sidebar
	useEffect(() => {
		let newSidebarTop = navbarRef.current.getBoundingClientRect().height;
		if ( lastSidebarTop === newSidebarTop ) return;
		lastSidebarTop = newSidebarTop;
		setSidebarTop(newSidebarTop);
	}, [navbarCollapse]);

	return (
		<React.Fragment>
			<Navbar ref={navbarRef} className="navbar fixed-top navbar-expand-lg">
	        	<NavLink to="/" className="navbar-brand">
	        		<img src={assets("/img/Logo-Plenoil-150x34.png")} alt="PLENOIL" />
	        	</NavLink>
				
				<button onClick={() => setNavbarCollapse(!navbarCollapse)} className="navbar-toggler" type="button">
					<FontAwesomeIcon icon={faBars} />
			    </button>

			    <div className={(navbarCollapse ? 'collapse' : '') + ' navbar-collapse'}>
			      	<div id="user" className="d-flex flex-column me-auto justify-content-center py-2 py-lg-0 ps-2">
						<small>BIENVENIDO</small>
						<span>{ getUser()?.name}</span>
	        			<NavLink to="/logout" className="btn btn-link p-0 m-0">DESCONECTAR</NavLink>
					</div>

					<Clock />

					<div className="d-flex ms-2 justify-content-center py-2 py-lg-0 me-2">
						&nbsp;&nbsp;&nbsp;&nbsp;
						&nbsp;&nbsp;&nbsp;&nbsp;
						&nbsp;&nbsp;&nbsp;&nbsp;
						&nbsp;&nbsp;&nbsp;&nbsp;
					</div>
			    </div>
			</Navbar>

			
			<Sidebar opened={!navbarCollapse} top={sidebarTop}>
				{ hasCapability('tankertrucks') &&
					<>
						<SidebarTitle borderColor={'var(--plenoil-blue)'} selected={location.pathname.indexOf('tankertrucks') !== -1}>
							<NavLink to="/tankertrucks"><i className="bi bi-truck"></i> Cisternas</NavLink>
						</SidebarTitle>

						{ location.pathname.indexOf('tankertrucks') !== -1 && 
							<>
								<SidebarItem>
									<NavLink to="/tankertrucks/"><i className="bi bi-chevron-right"></i> Listado cisternas</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/tankertrucks/add-manual"><i className="bi bi-chevron-right"></i> Envío manual</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/tankertrucks/status/0"><i className="bi bi-chevron-right"></i> Programadas</NavLink>	
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/tankertrucks/status/1"><i className="bi bi-chevron-right"></i> En camino</NavLink>	
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/tankertrucks/status/2"><i className="bi bi-chevron-right"></i> Con retraso</NavLink>	
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/tankertrucks/status/3"><i className="bi bi-chevron-right"></i> Incidencias</NavLink>	
								</SidebarItem>
							</>
						}
					</>
				}


				{ hasCapability('prices') &&
					<>
						<SidebarTitle borderColor={'var(--plenoil-red)'} selected={location.pathname.indexOf('prices') !== -1}>
							<NavLink to="/prices"><i className="bi bi-droplet-half text-plenoil-red"></i> Precios</NavLink>
						</SidebarTitle>

						{ location.pathname.indexOf('prices') !== -1 && 
							<>
								<SidebarItem>
									<NavLink to="/prices"><i className="bi bi-chevron-right"></i> Competencia</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/prices-check"><i className="bi bi-chevron-right"></i> Comprobación</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/prices-zones"><i className="bi bi-chevron-right"></i> Zonas</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/prices-app"><i className="bi bi-chevron-right"></i> Precios App</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/prices-indicus-webservice-sends"><i className="bi bi-chevron-right"></i> Envíos WS Indicus</NavLink>
								</SidebarItem>
							</>
						}
					</>
				}

				{ hasCapability('probes') &&
					<>
						<SidebarTitle borderColor={'var(--plenoil-orange)'} selected={location.pathname.indexOf('probes') !== -1}>
							<NavLink to="/probes" className="me-3"><i className="bi bi-card-list"></i> Sondas</NavLink>
							<ProbesWarningGlobe />
						</SidebarTitle>
						
						{ location.pathname.indexOf('probes') !== -1 && 
							<>
								<SidebarItem>
									<NavLink to="/probes"><i className="bi bi-chevron-right"></i> Listado sondas</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/zones"><i className="bi bi-chevron-right"></i> Zonas</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/middleware-stations"><i className="bi bi-chevron-right"></i> Estaciones</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/probes-warnings"><i className="bi bi-chevron-right"></i> Avisos</NavLink>
								</SidebarItem>
							</>
						}
					</>
				}

				{ hasCapability('supplies') &&
					<>
						<SidebarTitle borderColor={'var(--plenoil-brown-light)'} selected={location.pathname.indexOf('supplies') !== -1}>
							<NavLink to="/supplies/station"><i className="bi bi-printer text-plenoil-brown-light"></i> Suministros</NavLink>			
						</SidebarTitle>

						{ location.pathname.indexOf('supplies') !== -1 && 
							<>
								<SidebarItem>
									<NavLink to="/supplies/station"><i className="bi bi-chevron-right"></i> Inventario</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/supplies/bc-orderstc/station"><i className="bi bi-chevron-right"></i> Comprobaciones</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/supplies/planning/station"><i className="bi bi-chevron-right"></i> Planificación</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/supplies/wash-switch"><i className="bi bi-chevron-right"></i> Activación lavaderos</NavLink>
								</SidebarItem>
							</>
						}			
					</>
				}

				{ hasCapability('conteos') &&
					<SidebarTitle borderColor={'var(--plenoil-black)'} selected={location.pathname.indexOf('conteos') !== -1}>
						<NavLink to="/conteos"><i className="bi bi-arrow-counterclockwise text-plenoil-black"></i> Conteos</NavLink>						
					</SidebarTitle>
				}

				{ hasCapability('users') &&
					<>
						<SidebarTitle borderColor={'var(--plenoil-gray-500)'} selected={location.pathname.indexOf('users') !== -1}>
							<NavLink to="/users"><i className="bi bi-person"></i> Usuarios</NavLink>						
						</SidebarTitle>
						
						{ location.pathname.indexOf('users') !== -1 && 
							<>
								<SidebarItem>
									<NavLink to="/users"><i className="bi bi-chevron-right"></i> Listado usuarios</NavLink>
								</SidebarItem>
								<SidebarItem>
									<NavLink to="/users/add"><i className="bi bi-chevron-right"></i> Añadir usuario</NavLink>
								</SidebarItem>
							</>
						}
					</>
				}
				

				{/*<li className="nav-item">
		        	<NavLink to="/probes" className="nav-link">Sondas</NavLink>
		        </li>
		        <li className="nav-item">
		        	<NavLink to="/zones" className="nav-link">Zonas</NavLink>
		        </li>
		        <li className="nav-item">
		        	<NavLink to="/middleware-stations" className="nav-link">Estaciones</NavLink>
		        </li>
		        <li className="nav-item sign-out">
		        	<a href="/" onClick={(e) => logout(e)}>
						<FontAwesomeIcon icon={faSignOutAlt} />
					</a>
		        </li>*/}
			</Sidebar>
						
			<Content>
				{props.children}
			</Content>
		</React.Fragment>
	);
}

const Clock = (props) => {
	const [time, setTime] = useState(null);

	useEffect(() => {
		const setCurrentTime = () => {
			setTime( moment().format('HH:mm:ss') );
		}
		setCurrentTime();
		timeInterval = setInterval(() => {
			setCurrentTime();
		}, 1000);

		return function cleanup() {
			clearInterval(timeInterval);
		}
	}, []);

	return (
		<div id="clock">
			<i className="bi bi-clock"></i>
			<span>{ time }</span>
		</div>
	);
}