import React from 'react';
import styled from 'styled-components';
import Details from './Details';
import Speedometer from './Speedometer';
import { assets } from 'helpers/generic';

const OilRowStyled = styled.div`
    display: flex;
    padding: 10px 10px;
    border-bottom: 2px solid white;

    .name {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;

        .percent-circle {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: var(--color);
            border-radius: 50%;
            color: white;
            aspect-ratio: 1;
            font-size: 12px;
            width: 35px;
        }
    }

    .details {
        width: 40%;
        margin-top: 2px;
    }

    .speedometer {
        width: 40%;
        padding-left: 10px;
        position: relative;

        .h2o_warning {
            position: absolute;
            right: -5px;
            top: -5px;
            height: 20px;
        }
    }
`;

const OilRow = (props) => {
    let oil = props.oil;
    let warningPercent = props.warningPercent;

    return (
        <OilRowStyled className={'oil-row'} style={{'--color': oil.color}}>
            <div className="name">
                {oil.name}
                <div className="percent-circle">
                    {oil.percent.toFixed(0)}%
                </div>
            </div>
            <div className="details">
                <Details {...oil} />
            </div>
            <div className="speedometer">
                <Speedometer {...oil} warningPercent={warningPercent} />
                {oil.h2o_warning && <img src={assets('/img/iconoagua.svg')} alt="warning" className="h2o_warning" />}
            </div>
        </OilRowStyled>
    );
}

export default OilRow;