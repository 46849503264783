import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import { loader } from 'helpers/generic';
import Probes from 'services/Probes';
import { hasCapability } from 'helpers/user';

const DetailsStyled = styled.div` 
    position: relative;
    font-size: 13px;
    line-height: 16px;
    color: gray;
    font-weight: normal;

    b {
        color: black;
    }

    .cristal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--bs-gray-300);
        z-index: 2;
    }

    .real_capacity_wrapper {
        position: relative;

        button {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            right: -20px;
            top: -5px;
            border: 0;
            background: none;
            color: var(--plenoil-orange);
            background: lightgray;
            border-radius: 50%;
            width: 15px;
            height: 15px;
        }

        input[type=number] {
            width: 50%;
            border: 0;
            text-align: right;
        }
    }

    div.d-inline-block {
        letter-spacing: -0.4px;
    }
`;

const Details = (props) => {
    let inputRef = useRef(null);

    let [loading, setLoading] = useState(false);
    let [editing, setEditing] = useState(false);

    let [capacity, setCapacity] = useState(props.capacity ?? 0);

    let capacityOctan = props.capacity_octan ?? 0;
    let difference = props.difference ?? 0;
    let available = props.available ?? 0;
    let temperature = props.temperature ?? 0;
    let tankId = props.tankId ?? null;
    let editable = props.editable ?? false;
    let altura = props.altura ?? false;
    let alturaH2O = props.alturaH2O ?? false;

    let reload = props.reload ?? null;

    useEffect(() => {
        if ( editing ) inputRef.current.focus();
    }, [editing]);

    const saveCapacity = async () => {
        setLoading(true);

        let result = await Probes.updateOctanTankCapacityReal(tankId, capacity);
        if ( result.errors ) toast.error('Error al actualizar la capacidad del tanque');

        setLoading(false);
        setEditing(false);

        if ( reload ) reload();
    }
    
    return (
        <DetailsStyled>            
            {loading && <div className="cristal">{loader}</div>}
            <div className="real_capacity_wrapper d-flex justify-content-between">
                <div className="d-inline-block">CAPACIDAD REAL:</div> 

                { (hasCapability('probes', 'edit') && editable) &&
                    <button 
                        className="btn-edit"
                        onClick={() => editing ? saveCapacity() : setEditing(true)}
                    >
                        <i className={'bi ' + (editing ? 'bi-check' : 'bi-pencil')}></i>
                    </button>
                }

                { editing && <input ref={inputRef} type="number" className="no-arrows" onChange={(e) => setCapacity(e.target.value)} onKeyPress={(e) => e.key === 'Enter' ? saveCapacity() : null} value={capacity ?? 0} /> }
                { !editing && <b>{numeral(capacity).format('0,0[.]000')}</b>}
            </div>
            <div className="d-flex justify-content-between">
                <div className="d-inline-block">CAPACIDAD TANQUE:</div> <b>{numeral(capacityOctan).format('0,0[.]0')}</b>
            </div>
            <div className="d-flex justify-content-between">
                <div className="d-inline-block">LITROS VACIOS:</div> <b>{numeral(difference).format('0,0[.]0')}</b>
            </div>
            <div className="d-flex justify-content-between">
                <div className="d-inline-block">LITROS EN TANQUE:</div> <b>{numeral(available).format('0,0[.]0')}</b>
            </div>
            <div className="d-flex justify-content-between">
                <div className="d-inline-block">TEMPERATURA:</div> <b>{numeral(temperature).format('0,0[.]0')}º</b>
            </div>
            { altura !== false &&
                 <div className="d-flex justify-content-between">
                    <div className="d-inline-block">ALTURA COMBUSTIBLE:</div> <b>{numeral(altura).format('0,0[.]0')}</b>
                </div>
            }
            { alturaH2O !== false &&
                 <div className="d-flex justify-content-between">
                    <div className="d-inline-block">ALTURA H2O:</div> <b>{numeral(alturaH2O).format('0,0[.]0')}</b>
                </div>
            }
        </DetailsStyled>
    )
}

export default Details;