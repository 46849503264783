import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import axios from 'axios';
import { getUser, setUser } from 'helpers/user';

export default function Logout() {

    useEffect(() => {
    	const logout = () => {
    		setUser(null, true);
	    	axios.post('api/logout', {}).then((response) => {
		    	setUser(null, true);
		    	window.location.reload();
		  	});	    	
	    }
	    logout();
    }, []);

	return (
		<React.Fragment>
			{ 
				getUser() ?
					<div className="text-center"> 
						Cerrando sesión...
					</div>
				:
				<Navigate to="/" />
			}
		
		</React.Fragment>
	)
}