import useStore from "store";

export function hasCapability(capability, action = 'read') {
	let user = useStore.getState().user;
	let has_capability = false;
	try {
		 has_capability = user?.capabilities[capability] && user?.capabilities[capability][action] ? true : false;
	} catch (e) {}
	return has_capability ? true : false;
}

export const getUser = () => {
	return useStore.getState().user;
}
 
export function setUser(data, reload = false) {
	useStore.getState().dispatch({
		type: 'setUser',
		data: data,
		forceReload: reload ? Math.random() : null
	});
} 