import axios from 'axios';

export default class Supplies {

	static axiosCancelToken = axios.CancelToken.source();

	static getOrders = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/admin-orders-list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getOrder = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/admin-get-order/' + id, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static updateOrderLineQty = (orderline_id, qty) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/admin-update-orderline-qty', {
			orderline_id: orderline_id,
			qty: qty
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static approveOrder = (order_id) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/admin-approve-order/' + order_id, {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static cancelOrder = (order_id) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/admin-cancel-order/' + order_id, {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static retrySendLine = (orderline_id) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/admin-retry-send-orderline/' + orderline_id, {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static getAllowedCodesList = (type) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/admin-get-allowed-codes', {
			params: {
				type: type
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static addAllowedCode = (code, type) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/admin-add-allowed-code', {
			code: code,
			type: type
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static removeAllowedCode = (id) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/admin-remove-allowed-code/' + id, {}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static getBCOrdersTc = (params = {}) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/admin-bc-orderstc-list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getBCOrderTc = (id) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/admin-bc-orderstc-get/' + id, {
			params: {},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static getPlanning = (params) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/planning-list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static toggleStationHasWash = (station_id, status) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/station-toggle-has-wash', {
			station_id: station_id,
			status: status
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static setNextPlanning = (station_id, type, date) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/admin-set-next-planning', {
			station_id: station_id,
			date: date,
			type: type
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static getPlanningGlobalConfig = (type) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/planning/get-global-config/' + type, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static setPlanningGlobalConfig = (type, data, recalculate = false) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/planning/set-global-config/' + type + (recalculate ? '?recalculate=true' : ''), data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static getPlanningStationConfig = (type, station_id) => {
		this.newAxiosCancelToken();

		return axios.get('api/supplies/planning/get-station-config/' + type + '/' + station_id, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static setPlanningStationConfig = (type, station_id, data, recalculate = false) => {
		this.newAxiosCancelToken();

		return axios.post('api/supplies/planning/set-station-config/' + type + '/' + station_id + (recalculate ? '?recalculate=true' : ''), data, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}