export default function Details(props) {
	let data = props.data;

	let request = data?.request ? JSON.stringify(data?.request) : null;
	let reply = data?.reply ? JSON.stringify(data?.reply) : null;

	return (
		<tr className="details">
			<td colSpan="100%">
				<div className="row">
					<div className="col-md-6">
						<h4>PETICIÓN</h4>
						<pre style={{whiteSpace: 'wrap'}}><code>{request}</code></pre>
					</div>
					<div className="col-md-6">
						<h4>Respuesta</h4>
						<pre style={{whiteSpace: 'wrap'}}><code>{reply}</code></pre>
					</div>
				</div>
			</td>
		</tr>
	);
}


