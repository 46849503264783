import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { hasCapability } from 'helpers/user';
import { default as PricesService } from 'services/Prices';

const Station = styled.div`
	background: white;
	cursor: pointer;
	display: inline-flex;
	margin: 2px 5px;
	border-radius: 3px;
	padding: 5px 10px;
	user-select: none;

	&:hover {
		position: relative;
		
		&:after {
			content: '×';
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			background: rgba(255,255,255,0.7);
			color: var(--bs-red);
			font-weight: bold;
			font-size: 30px;
		}
	}
`;

export default function ZonesForm() {
	const navigate = useNavigate();
	const params = useParams();

	let [data, setData] = useState({
		name: '',
		stations: []
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				let zone = await PricesService.getZone(params.id);
				setData({...zone});
			}
			getData();
		}
	}, [params.id]);

	const loadStations = async (input, callback) => {

		let stations = await PricesService.getStations('name', 'asc', input);
		let formatted = stations.map((el) => {
			return {
				value: el.id, 
				label: el.name
			};
		});
		callback(formatted);
	}

	const addStation = (id, name) => {
		let newStations = [...data.stations];

		let idx = newStations.findIndex((el) => el.id === id);
		if ( idx !== -1 ) return;

		newStations.push({
			id: id,
			name: name
		});
		setData({...data, stations: newStations});
	}

	const removeStation = (id) => {
		let newStation = [...data.stations];
		let idx = newStation.findIndex((el) => el.id === id);
		newStation.splice(idx, 1);
		setData({...data, stations: newStation});
	}

	const setName = (name) => {
		setData({...data, name: name});
	}

	const saveData = async () => {
		setErrors({});

		let dataToSend = {
			...data,
			stations: data.stations.map((el) => el.id)
		};

		let result = await PricesService.saveZone(dataToSend);
		if ( result.status ) {
			toast.success('Datos guardados');
			navigate(-1);
		} else {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		}
	}

	const deleteZone = async () => {
		const c = window.confirm('¿Seguro que quieres eliminar esta zona?');
		if ( !c ) return false;

		let result = await PricesService.deleteZone(data.id);
		if ( result.status ) {
			toast.success('Zona eliminada');
			navigate(-1);
		} else {
			toast.error('Ha ocurrido un error al eliminar');
		}
	}

	return (
		<LoggedLayout>
			<div className="col-md-12 d-flex flex-column align-items-center">
				<div className="col-md-6 mb-3">
					<NavLink to="/prices-zones" className="btn btn-sm btn-secondary float-end">Volver</NavLink>

					<h4 className="mb-0">{ data.id ? 'Modificar' : 'Nueva' } zona</h4>
				</div>

				<div className="col-md-6 mb-3">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-md-8">
									<div className="mb-2">
										<label htmlFor="name">Nombre</label>
										<input type="text" id="name" className="form-control form-control-sm" onChange={(e) => setName(e.target.value)} value={data.name} />
										{ errors.name &&
											<div className="invalid-feedback d-block">{ errors.name[0] }</div>
										}
									</div>
								</div>
							</div>
							<div className="mb-2">
								<label htmlFor="stations">Estaciones</label>
								<div className="border rounded bg-light p-2">
									<div className="row justify-content-end">
										<div className="col-md-6">
											<EmpoweredSelector
												load={(input, callback) => loadStations(input, callback)}
												onChange={(value, text) => addStation(value, text)}
												timeout={500}
												label="Estaciones"
												placeholder="Estaciones"
												value={null}
												except={data.stations?.map((el) => el.id)}
											/>
										</div>
									</div>
									<div className="stations-wrapper mt-2">
										{ data.stations?.map((el) => {
											return  <Station key={el.id} onClick={() => removeStation(el.id)}>
														{ el.name }
													</Station>;
										}) }
									</div>
									{ errors.stations &&
										<div className="invalid-feedback d-block">{ errors.stations[0] }</div>
									}
								</div>
							</div>
						</div>
						<div className="card-footer d-flex justify-content-between">
							{ (data.id && hasCapability('prices', 'delete')) &&
								<button className="btn btn-sm btn-link text-danger" onClick={() => deleteZone()}>Eliminar</button>
							}

							{ ((!data.id && hasCapability('prices', 'add')) || (data.id && hasCapability('prices', 'edit'))) &&
								<button className="btn btn-sm btn-plenoil-primary" onClick={() => saveData()}>Guardar</button>
							}
						</div>
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


