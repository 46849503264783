import axios from 'axios';

export default class TankerTrucks {

	static axiosCancelToken = axios.CancelToken.source();
	
	static deleteBulk = (checksIds) => {
		this.newAxiosCancelToken();

		return axios.post('api/tankertrucks/delete-bulk', checksIds, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			return error.response.data;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}