import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ChatService from 'services/Chat';
import EmpoweredSelector from 'components/EmpoweredSelector';
import MessagesWindow from './MessagesWindow';
import NotificationAudio from './notification_normal.mp3';

const ChatWidgetStyled = styled.div`
    --border-width: 4px;
    --border: var(--border-width) solid var(--bs-gray-500);
    --width: 500px;

	position: fixed;
    top: ${props => props.opened ? 7.5 : 50}%;
    right: ${props => props.opened ? 0 : 'calc(var(--width) * -1)'};
    height: 85%;
    width: var(--width);
    background: var(--bs-gray-100);
    z-index: 99999;
    border: var(--border);
    border-right: 0;
    border-bottom-left-radius: 20px;
    transition: all 0.3s;

    @media (max-width: 500px) {
        width: 100%;
        height: 100%;
        top: ${props => props.opened ? 0 : 50}%;
        right: ${props => props.opened ? 0 : 'calc(100% * -1)'};
        margin: 0;
        border: 0;
    }
    
    .chat-widget-inner {
        position: relative;
        height: 100%;

        .toggle-button {
            position: absolute;
            top: -4px;
            left: -40px;
            width: 40px;
            height: 40px;
            padding-top: 4px;
            background: var(--bs-gray-100);
            color: var(--bs-gray-600);
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: var(--border);
            border-left: var(--border);
            border-bottom: var(--border);
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            cursor: pointer;
            ${props => props.unread > 0 && !props.opened ? 'border-color: var(--plenoil-red);' : ''}

            @media (max-width: 500px) {
                ${props => props.opened ? 'top: 2px; right: 0; left: unset; border: 0;' : ''}
            }

            &:hover {
                ${props => !props.opened ? 'border-color: var(--plenoil-blue); color: var(--plenoil-blue);' : ''}
            }

            .bi {
                font-size: ${props => props.unread > 0 ? 10 : 15}px;
                ${props => props.unread > 0 ? 'margin-top: 8px; margin-left: -12px;' : ''}
                ${props => props.unread > 0 && !props.opened ? 'color: var(--plenoil-red);' : ''}
            }

            .total-unseen {
                display: flex;
                justify-content: center;
                align-items: center;
                background: ${props => props.unread > 0 && !props.opened ? 'var(--plenoil-red)' : 'var(--bs-gray-600)'};
                color: white;
                width: 15px;
                height: 15px;
                border-radius: 50%;
                position: absolute;
                top: 2px;
                right: 5px;
                font-size: 10px;
            }
        }

        .station-selector {
            height: 10%;
            padding: 10px 20px;

            .empowered-selector {
                width: 100%;
                max-width: 60%;
            }
        }

        .stations-list {
            overflow-y: auto;
            height: 90%;
            padding-top: 0px;

            button {
                 padding: 10px 20px;
            }
        }
    }
`;

const StationButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    border: 0;
    background: 0;
    font-size: 14px;
    letter-spacing: -0.5px;
    font-weight: 500;

    &:not(:last-of-type) {
        border-bottom: calc(var(--border-width) / 2) solid var(--bs-gray-400);
    }

    &:hover {
        background: var(--bs-gray-300);
    }

    span.badge {
        padding: 2px 4px;
        font-weight: 300;
        letter-spacing: 0px;
        text-transform: uppercase;
        margin-left: 5px;
    }

    span.unread {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: var(--plenoil-green);
        color: white;
        font-size: 10px;
        margin-left: 5px;
    }
`;

let stationsOpenedInterval = null;

let notificationAudio = new Audio(NotificationAudio);

const Chat = (props) => {
    let [stations, setStations] = useState([]);
    let [opened, setOpened] = useState(false);
    let [stationOpened, _setStationOpened] = useState(null);
    let [totalUnseenMessages, setTotalUnseenMessages] = useState(0);
    let lastTotalUnseenMessagesRef = useRef(0);
    let stationOpenedRef = useRef(null);
    let setStationOpened = (status) => {
        _setStationOpened(status);
        stationOpenedRef.current = status;
    }

    useEffect(() => {
        const getStationsOpened = async () => {
            let stations = await ChatService.getStations(true);
            setStations(stations);
        }
        getStationsOpened();

        stationsOpenedInterval = setInterval(() => {
            getStationsOpened()
        }, 5000);

        return function cleanup() {
            clearInterval(stationsOpenedInterval);
            ChatService.cancel();
        }
    }, [opened]);

    useEffect(() => {
        let total = stations?.reduce((carry, item) => carry+=item.unread, 0);
        setTotalUnseenMessages(total);
    }, [stations]);

    useEffect(() => {
        if ( totalUnseenMessages > lastTotalUnseenMessagesRef.current && !stationOpenedRef.current ) {
            lastTotalUnseenMessagesRef.current = totalUnseenMessages;
            notificationAudio.play();
        }
    }, [totalUnseenMessages]);

    useEffect(() => {
        let openChatListener = document.addEventListener('open-chat', async (e) => {
            let stations = await ChatService.getStations(false);

            let stationId = e.detail.stationId;
            let channel = e.detail.channel;
            let station = stations.filter(el => el.id === parseInt(stationId) && el.channel.id === channel)[0] ?? null;

            if ( station ) {
                setStationOpened(station);
                setOpened(true);
            }
        }, false);

        return function cleanup() {
            document.removeEventListener('open-chat', openChatListener);
        }
    }, []);

    const loadStations = async (input, callback) => {
        let stations = await ChatService.getStations(false, input);

		let formatted = stations?.map((el) => {
            return {
                value: el, 
                label: (
                    <StationButton onClick={() => setStationOpened(el)}>
                        {el.name}
                        <span className="badge" style={{background: el.channel?.color}}>{el.channel?.name}</span>
                    </StationButton>
                )   
            };
        });

        callback(formatted);
	}

	return (
		<ChatWidgetStyled id="chat-widget" opened={opened} unread={totalUnseenMessages}>
            <div className="chat-widget-inner">
                <div 
                    className="toggle-button" 
                    onClick={() => {
                        opened = !opened;
                        setOpened(opened);

                        if ( !opened ) {
                            stationOpenedRef.current = false;
                            lastTotalUnseenMessagesRef.current = 0;
                        } else {
                            if ( stationOpened ) {
                                stationOpenedRef.current = stationOpened;
                            }
                        }
                    }}
                >
                    <i className="bi bi-chat-right-fill"></i>
                    {totalUnseenMessages > 0 && <span className="total-unseen">{totalUnseenMessages}</span>}
                </div>

                <div className="station-selector">
                    <EmpoweredSelector
                        load={(input, callback) => loadStations(input, callback)}
                        onChange={(value, text) => setStationOpened(value)}
                        timeout={200}
                        label={'Seleccionar estación'}
                        value={null}
                    />
                </div>

                <div className="stations-list">
                    {stations?.map((el, idx) => {
                        return (
                            <StationButton key={idx} onClick={() => setStationOpened(el)}>
                                {el.name}
                                <span className="badge" style={{background: el.channel?.color}}>{el.channel?.name}</span>
                                {el.unread > 0 && <span className="unread">{el.unread}</span>}
                            </StationButton>
                        );
                    })}
                </div>
                { (stationOpened !== null && opened) && 
                    <MessagesWindow 
                        station={stationOpened} 
                        parentOpened={opened}
                        close={() => {
                            setStationOpened(null);
                            lastTotalUnseenMessagesRef.current = 0;
                        }}
                    /> 
                }
            </div>
		</ChatWidgetStyled>
	);
};

export default Chat;