import React from "react";

export function assets(asset) {
	return process.env.PUBLIC_URL + asset;
}

export const loader = <div id="loader" style={{width: '100px', margin: '0 auto'}}><img src={assets('/img/loader.gif')} style={{width: '100%', height: '100%'}} alt="loader" /></div>;

export const cristalLoader = <div 
								id="loader" 
								style={{
									position: 'absolute',
									top: 0, 
									left: 0,
									width: '100%',
									height: '100%',
									zIndex: 99999,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									background: 'rgba(255,255,255,0.5)'
								}}
							>
								<img src={assets('/img/loader.gif')} style={{width: '80px', height: '50px'}} alt="loader" />
							</div>;


export const jsonToArray = (json) => {
	let array = [];
	for(let i in json) array.push({key: i, value: json[i]});
	return array;
}

export const formatNumber = (number, decimals = 0, force_decimals = true) => {
	if ( isNaN(number) ) number = 0;
	else number = parseFloat(number);

	const split = number.toString().split('.');
	if ( split[1] ) {
		let integer = parseInt(split[1]);
		if ( integer > 0 ) {
			if ( decimals ) {
				if ( force_decimals ) number = number.toFixed(decimals);
				else {
					let decimalsStr = split[1];
					decimalsStr.replaceAll('0', '');
					number = number.toFixed(decimalsStr.length > decimals ? decimals : decimalsStr.length);
				}
			}
		}
		else number = parseInt(number);
	} else {
		number = parseInt(number);
	}
	return number;
}
export const readableSize = (x) => {
	const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let l = 0, n = parseInt(x, 10) || 0;
	while(n >= 1024 && ++l){
		n = n/1024;
	}
	return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}
export const downloadFile = (file) => {
	const axios = require('axios').default;

	return axios({
	    url: '/api/manager/documents/download-file/' + file.id, //your url
	    method: 'GET',
	    responseType: 'blob', // important
	}).then((response) => {
	    const url = window.URL.createObjectURL(new Blob([response.data]));
	    const link = document.createElement('a');
	    link.href = url;
	    link.setAttribute('download', response.headers.file_name ?? 'file'); //or any other extension
	    document.body.appendChild(link);
	    link.click();
	    link.parentElement.removeChild(link);
	});
}
export const ucfirst = (str) => {
    return str[0].toUpperCase() + str.slice(1);
}

export const delay = ms => new Promise(res => setTimeout(res, ms))

export const hex2rgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    
    // return {r, g, b} 
    return { r, g, b };
}