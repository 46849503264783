import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const StyledElement = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: var(--plenoil-red);
    color: white;
    font-size: 11px;
    line-height: 100%;
    animation: blinker 1s linear infinite;
    cursor: pointer;

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
`;

let axiosCancelToken = null;

export default function ProbesWarningGlobe() {
    const navigate = useNavigate();

	const [count, setCount] = useState(null);

    useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

    useEffect(() => {
		let getWarningsCount = () => {
            return axios.get('api/probes-warnings/list', {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
                let count = response.data.reduce((carry, item) => {
                    carry += item.probewarning?.goa_warning_at ? 1 : 0;
                    carry += item.probewarning?.sp95_warning_at ? 1 : 0;
                    return carry;
                }, 0);
                setCount(count);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
        }
        getWarningsCount();

        let interval = setInterval(() => {
            getWarningsCount();
        }, 10000);

		return function cleanup() {
           	clearInterval(interval);
        }
	}, []);

	return (
        <>
            { count > 0 &&
                <StyledElement onClick={() => navigate('/probes-warnings')}>
                    {count}
                </StyledElement>
            }
        </>
	);
}