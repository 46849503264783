import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { cristalLoader } from 'helpers/generic';
import { default as PricesService } from 'services/Prices';

const ScheduleFormStyled = styled.div` 
    position: relative;
    margin-top: 30px;
    font-size: 12px;

    .station-check-row {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        user-select: none;
        cursor: pointer;

        input {
            margin-right: 5px;
            width: 18px;
            height: 18px;
        }

        div {
            font-size: 12px;
            line-height: 10px;

            small {
                font-size: 9px;
                color: gray;
            }
        }
    }

    input.with-errors {
        background: var(--plenoil-red);
        color: white;
        border-color: var(--plenoil-red);
    }
`;

export default function ScheduleForm(props) {
    let stationId = props.stationId;

    let [forceReload, setForceReload] = useState(false);
    let [loading, setLoading] = useState(false);
    let [competitionStations, setCompetitionStations] = useState([]);
    let [intervals, setIntervals] = useState([]);
    let [selectedCompetitionStations, setSelectedCompetitionStations] = useState([]);
    let [errors, setErrors] = useState({});

    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            let data = await PricesService.getStationCompetitionSchedule(stationId);
            setLoading(false);

            setCompetitionStations(data?.stations ?? []);
            setSelectedCompetitionStations(data?.stations.filter(el => el.id).map(el => el.code));
            setIntervals(data?.intervals ?? []);
        }
        getData();
    }, [stationId, forceReload]);
    
    const addInterval = () => {
        let newIntervals = [...intervals];
        newIntervals.push({id: null, from: null, to: null});
        setIntervals(newIntervals);
    }

    const updateInterval = (idx, field, value) => {
        let newIntervals = [...intervals];
        newIntervals[idx][field] = value;
        setIntervals(newIntervals);
    }

    const deleteInterval = (idx) => {
        let newIntervals = [...intervals];
        newIntervals.splice(idx, 1);
        setIntervals(newIntervals);
    }

    const toggleSelectedCompetitionStation = (code) => {
        let newSelectedCompetitionStations = [...selectedCompetitionStations];
        let idx = newSelectedCompetitionStations.indexOf(code);
        if (idx === -1) {
            newSelectedCompetitionStations.push(code);
        } else {
            newSelectedCompetitionStations.splice(idx, 1);
        }
        setSelectedCompetitionStations(newSelectedCompetitionStations);
    }

    const saveData = async () => {
        // Prepare
        let data = {};
        data.station_id = stationId;
        data.competition = selectedCompetitionStations.map(el => {
            let competitionData = competitionStations.filter(cEl => cEl.code === el)[0] ?? null;
            if ( !competitionData ) return null;

            return {
                id: competitionData.id,
                code: competitionData.code,
                name: competitionData.name
            };
        }).filter(el => el !== null);
        data.intervals = [...intervals];

        // Save
        setErrors({});
        setLoading(true);
        let result = await PricesService.saveStationCompetitionSchedule(data);
        setLoading(false);

        // Show errors
        if ( result.errors ) {
            setErrors(result.errors);
            toast.error('No se han guardado los datos');
        } else {
            setForceReload(Math.random());
            toast.success('Se han guardado los datos');
        }
    }

    return (
        <ScheduleFormStyled>
            {loading && cristalLoader}
            <div className="row">
                <div className="col-md-12">
                    <h5>CONFIGURACION DE CHEQUEO COMPETENCIA EN APP</h5>
                </div>
                <div className="col-md-3" style={{borderRight: '1px solid gray'}}>
                    <h6 className="mb-1">LISTADO DE COMPETENCIA</h6>
                    {competitionStations?.map((el, idx) => {
                        return (
                            <label key={idx} className="station-check-row">
                                <input type="checkbox" checked={selectedCompetitionStations.includes(el.code)} onChange={(e) => toggleSelectedCompetitionStation(el.code)} />
                                <div>
                                    {el.name}<br />
                                    <small>{el.address}</small>
                                </div>
                            </label>
                        )
                    })}

                    {competitionStations?.length <= 0 && 'No hay estaciones de la competencia'}

                    {errors['competition'] && <div className="invalid-feedback d-block">{errors['competition'][0]}</div>}
                </div>
                <div className="col-md-3">
                    <div className="mb-2">
                        <h6 className="mb-1">
                            VISITAS 
                            <button className="btn btn-link btn-sm p-0" onClick={() => addInterval()} disabled={!competitionStations?.length}><i className="bi bi-plus-circle-fill text-plenoil-primary"></i></button>
                        </h6>

                        {intervals?.map((el, idx) => {
                            return (
                                <div className="mb-1" key={idx}>
                                    Desde <input type="time" value={el.from ?? ''} onChange={(e) => updateInterval(idx, 'from', e.target.value)} className={errors['intervals.' + idx + '.from'] ? 'with-errors' : ''} /> 
                                    &nbsp;hasta <input type="time" value={el.to ?? ''} onChange={(e) => updateInterval(idx, 'to', e.target.value)} className={errors['intervals.' + idx + '.to'] ? 'with-errors' : ''} /> 
                                    <button 
                                        className="btn btn-link btn-sm p-0" 
                                        onClick={() => deleteInterval(idx)}
                                    >
                                        <i className="bi bi-x-circle-fill text-plenoil-red"></i>
                                    </button>
                                </div>
                            );
                        })}

                        {intervals?.length === 0 && 'No hay visitas programadas'}

                        {errors['intervals'] && <div className="invalid-feedback d-block">{errors['intervals'][0]}</div>}
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                    <button className="btn btn-sm btn-plenoil-primary p-0 px-2" onClick={() => saveData()}>GUARDAR</button>
                </div>
            </div>
        </ScheduleFormStyled>
    );
}