import axios from 'axios';

export default class Stations {

	static axiosCancelToken = axios.CancelToken.source();
	
	static getList = (params = {}) => {
		this.newAxiosCancelToken();

		// return axios.get('api/prices/stations-list', {
		return axios.get('api/stations/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}