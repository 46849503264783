import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { hasCapability } from 'helpers/user';
import { default as StationsService } from 'services/Stations';
import { default as PricesService } from 'services/Prices';

let axiosCancelToken = null;

export default function PricesCheckForm() {
	const navigate = useNavigate();

	let [data, setData] = useState({
		station: null,
		date: moment().format('YYYY-MM-DD HH:mm')
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const saveData = async () => {
		setErrors({});

		data.manual = true; // Set manual on DB

		let result = await PricesService.addCheck(data);
		if ( result.errors ) {
			setErrors(result.errors);
			toast.error('Ha ocurrido un error al guardar');
		} else {
			toast.success('Datos guardados');
			navigate('/prices-check');
		}
	}

	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const loadStations = async (input, callback) => {
		let stations = await StationsService.getList({
			search: input,
			no_paginate: true
		});
		
		callback(stations?.map(el => {
			return {
				value: el,
				label: el.name
			}
		}));
	}

	const selectStation = (value) => {
		setData({...data, station: value, station_id: value?.id});
	}

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-red text-white text-uppercase p-2">
					Comprobación manual
					<NavLink to={-1} className="btn btn-sm btn-light p-0 px-2 float-end">Volver</NavLink>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<div className="card shadow-sm">
					<div className="card-body">
						<div className="row">
							<div className="col-md-3">
								<div className="mb-2">
									<label>Estación</label>
									<EmpoweredSelector
										load={(input, callback) => loadStations(input, callback)}
										onChange={(value, text) => selectStation(value)}
										timeout={200}
										label={data.station?.name}
										value={data.station?.id ?? null}
									/>
									{ errors.station_id &&
										<div className="invalid-feedback d-block">{ errors.station_id[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Fecha</label>
									<input type="datetime-local" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ?? ''} />
									{ errors.date &&
										<div className="invalid-feedback d-block">{ errors.date[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Precio GOA</label>
									<input type="number" step="0.001" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('goa_price', e.target.value)} value={data.goa_price ?? ''} />
									{ errors.goa_price &&
										<div className="invalid-feedback d-block">{ errors.goa_price[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Litros SP95</label>
									<input type="number" step="0.001" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('sp95_price', e.target.value)} value={data.sp95_price ?? ''} />
									{ errors.sp95_price &&
										<div className="invalid-feedback d-block">{ errors.sp95_price[0] }</div>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="card-footer d-flex justify-content-between">
						<span>&nbsp;</span>

						{ hasCapability('tankertrucks', 'add') &&
							<button className="btn btn-sm btn-plenoil-blue-light" onClick={() => saveData()}>Guardar</button>
						}
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


