import React, { useEffect, useState } from 'react';
import LoggedLayout from '../../layouts/LoggedLayout';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import { jsonToArray } from 'helpers/generic';
import { default as PricesService } from 'services/Prices';
import CompetitionPricesFrame from './CompetitionPricesFrame';
import Details from './Details';
import EmpoweredSelector from 'components/EmpoweredSelector';

const TableResponsive = styled.div`

	@media (min-width: 992px) {
		overflow-x: visible;
	}
`;

const Table = styled.table`
	th,
	td {
		vertical-align: middle;
		position: relative;

		&:nth-child(1) {
			width: 40px;
			text-align: center;
		}

		&:nth-child(2) {
			cursor: pointer;
			user-select: none;

			.station_code {
				width: 22px;
				height: 22px;
				font-size: 10px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 10px;
			}
		}

		&:nth-child(3) {
			width: 100px;
			text-align: center;

			div {

			}
		}

		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6),
		&:nth-child(7),
		&:nth-child(8) {
			padding: 0;
			width: 135px;
			text-align: center;
		}
		
		&:nth-child(8) {
			width: 80px;
			text-align: center;

			&.chat {
				i {
					color: var(--plenoil-green);

				}

				&.disabled {
					opacity: 0.8;
				}
			}
		}
	}

	tr.opened {
		td {
			border-top: 2px solid var(--plenoil-blue);
		}
	}

	@media (min-width: 992px) {
		tbody {
			border-top-color: transparent !important;
		}

		th {
			position: sticky;
			top: 49px;
			z-index: 2;
			box-shadow: inset 0 -2px 0 var(--plenoil-blue);
			background: white !important;
		}
	}
`;

const PricesRowsContainer = styled.div` 
	display: flex;
	flex-direction: column;
	font-size: 14px;
	line-height: 16px;

	span {
		display: block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin-right: 5px;

		&.goa {
			background: var(--plenoil-black);
		}

		&.sp95 {
			background: var(--plenoil-green);
		}
	}

	div {
		display: flex;
		align-items: center;
		padding: 3px 10px;

		&:first-of-type {
			border-bottom: 1px solid #dee2e6;
		}
	}

	i {
		width: 12px;
	}

	u {
		color: var(--bs-gray-500);
		font-size: 9px;
		letter-spacing: -1px;
		margin-left: auto;
		text-decoration: none;
		padding-right: 5px;
	}
`;

const Filters = styled.div` 

	.empowered-selector {
		
		&:hover {

			.empowered-selector_label {
				background: white;
			}
		}	
	}
`;

let first_competition_load = true;

export default function Prices() {
	let [stations, setStations] = useState([]);
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [competitionPrices, setCompetitionPrices] = useState({});
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('name');
	let [onlyAlerts, setOnlyAlerts] = useState(false);
	let [zone, setZone] = useState(null);
	let [skeletonRows, setSkeletonRows] = useState(5);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	const openChat = (e, station_id) => {
		e.stopPropagation();

		const event = new CustomEvent('open-chat', {detail: {stationId: station_id, channel: 'prices'}});
		document.dispatchEvent(event);
	}

	const toggleDetails = (rowId) => {
		if ( competitionPrices[rowId] === undefined ) return; // Prevent toggle if data is not loaded yet

		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const reloadStationCompetitionPrices = async (station_octan_id) => {
		let competitionPrices = await PricesService.getCompetitionPrices([station_octan_id]);
		setCompetitionPrices((prev) => ({...prev, ...competitionPrices}));
	}

	useEffect(() => {
		const getStations = async () => {
			let stations = await PricesService.getStations(sortField, sortDirection);
			setStations([...stations]);
			setSkeletonRows(stations.length);
		}
		getStations();

		return function cleanup() {
			PricesService.cancel();
		}
	}, [sortField, sortDirection]);

	useEffect(() => {
		const getCompetitionPrices = async () => {
			if ( stations.length <= 0 ) return; 

			let competitionPrices = await PricesService.getCompetitionPrices(stations?.map(el => el.octan_id));
			setCompetitionPrices(competitionPrices);

			if ( first_competition_load ) {
				let existsAlerts = jsonToArray(competitionPrices).filter(el => el.value.alert).length > 0;
				if ( existsAlerts ) setOnlyAlerts(true);

				first_competition_load = false;
			}
		}
		getCompetitionPrices();

		let getDataInterval = setInterval(() => {
			getCompetitionPrices();
		}, 1000 * 60); // Every 60 seconds

		return function cleanup() {
			first_competition_load = true;
			clearInterval(getDataInterval);
			PricesService.cancel();
		}
	}, [stations]);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader>
					<span className="header-column header-title">Control de precios</span>
					<span className="header-column" role="button">
						<div className="header-column-text">
							<i className="bi bi-exclamation-triangle" style={{color: 'var(--plenoil-red)'}}></i>
						</div>
						<div className="header-column-number ms-2" style={{color: 'var(--plenoil-red)'}}>
							{jsonToArray(competitionPrices).filter(el => el.value.alert).length}
						</div>
					</span>
					<span className="header-column header-icon-end">
						<i className="bi bi-droplet-half text-plenoil-red"></i>
					</span>
				</PageHeader>
			</div>

			<Filters className="col-md-12 mb-3">
				<div className="bg-plenoil-red text-white p-2">
					<div className="row">
						<div className="col-md-2">
							Competencia
						</div>
						<div className="col-md-6">

						</div>
						<div className="col-md-2">
							<EmpoweredSelector
								load={async (input, callback) => {
									let zones = await PricesService.getZonesList({no_paginate: true, search: input});
									callback(zones.map((el) => {
										return {
											value: el, 
											label: el.name
										};
									}));
								}}
								placeholder="Zonas"
								onChange={(value) => setZone(value)}
								value={zone?.id ?? null}
								label={zone?.name ?? null}
								timeout={500}
							/>
						</div>
						<div className="col-md-2">
							<div className="form-check form-switch">
								<input className="form-check-input" type="checkbox" checked={onlyAlerts} onChange={(e) => setOnlyAlerts(e.target.checked)} />
								<label className="form-check-label">Sólo mostrar alertas</label>
							</div>
						</div>
					</div>
				</div>
			</Filters>

			<div className="col-md-12 mb-3">
				<TableResponsive className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<th>Estado</th>
								<ThSortable direction={sortDirection} active={sortField === 'name'} onClick={() => sortTableClick('name')}>Estación</ThSortable>
								<th>Plenoil</th>
								<th>Competencia 01</th>
								<th>Competencia 02</th>
								<th>Competencia 03</th>
								<th>Competencia 04</th>
								<th>Chat</th>
							</tr>
						</thead>
						<tbody>
							{ stations?.map((el, idx) => {
								let alert = competitionPrices ? competitionPrices[el.octan_id]?.alert ?? false : false;
								let stationCurrentPrices = competitionPrices ? competitionPrices[el.octan_id]?.current ?? [] : [];
								let stationAppPrices = competitionPrices ? competitionPrices[el.octan_id]?.app ?? [] : [];
								let stationMineturPrices = competitionPrices ? competitionPrices[el.octan_id]?.minetur ?? [] : [];

								// Reorder minetur first checked stations
								if ( stationMineturPrices && stationMineturPrices.length ) {
									// Sort by id
									stationMineturPrices.sort((a, b) => {
										if ( a.id < b.id ) return 1;
										return -1;
									});

									// Sort by checked
									stationMineturPrices.sort((a, b) => {
										if ( a.checked < b.checked ) return 1;
										return -1;
									});
								}

								// Show depending on alert
								if ( !alert && onlyAlerts ) return null;

								// Show depending if zone selected
								if ( zone && zone.stations.findIndex((zsEl) => zsEl.id === el.id) === -1 ) return null;

								// Prepare competitions first fours columns
								let competition_first_fours = [];
								for(let i = 0; i<4; i++) {
									// Get prices
									let competition = stationMineturPrices[i] ?? null;
									let appPrices = stationAppPrices[competition?.id] ?? null;
									
									// Append
									competition_first_fours.push(
										<td key={el.octan_id + '-' + i}>
											{ competition ? 
												<CompetitionPricesFrame 
													competition={competition} 
													app={appPrices}
													current={stationCurrentPrices} 
													reloadParent={() => reloadStationCompetitionPrices(el.octan_id)}
												/>
												:
												''
											}
										</td>
									);
								}

								return (
									<React.Fragment key={'station' + el.id}>
										<tr className={' ' + (detailsOpened.includes(el.octan_id) ? 'opened' : '')} onClick={() => toggleDetails(el.octan_id)}>
											<td>
												{alert === true &&
													<i className="bi bi-exclamation-triangle text-plenoil-red"></i>
												}
											</td>
											<td>
												<div className="d-flex justify-content-between">
													<div>
														{el.name}
													</div>
												
													<div className="d-flex">
														<span className="bg-dark text-white station_code">{el.octan_id}</span>
													</div>
												</div>	
											</td>
											<td className="p-0">
												<PricesRowsContainer>
													<div><span className="goa"></span> {stationCurrentPrices?.goa_price ?? '-'}</div>
													<div><span className="sp95"></span> {stationCurrentPrices?.sp95_price ?? '-'}</div>
												</PricesRowsContainer>
											</td>
											{competition_first_fours}
											<td className={'chat ' + (!el.pending_chat_messages_count ? 'disabled' : '')}>
												<button className="btn btn-link btn-sm" onClick={(e) => openChat(e, el.id)}><i className={'bi bi-chat-right' + (el.pending_chat_messages_count ? '-fill' : '')}></i></button>
											</td>
										</tr>
										{ detailsOpened.includes(el.octan_id) &&
											<Details 
												station={el}
												stationMineturPrices={stationMineturPrices.filter((el, idx) => idx > 3)} 
												stationAppPrices={stationAppPrices} 
												stationCurrentPrices={stationCurrentPrices} 
												reloadParent={() => reloadStationCompetitionPrices(el.octan_id)}
											/>
										}
									</React.Fragment>
								)
							})}

							{ stations && !stations.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ stations === undefined && <TrSkeleton rows={skeletonRows} columns={11} /> }
						</tbody>
					</Table>
				</TableResponsive>
			</div>
		</LoggedLayout>
	);
}