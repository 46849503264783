import React, { useEffect, useState } from 'react';
import { useSearchParams, NavLink } from "react-router-dom";
import LoggedLayout from '../../layouts/LoggedLayout';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import PageHeader from 'components/PageHeader';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import DateDropdown from 'components/DateDropdown';
import Details from 'pages/Supplies/Details';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as SuppliesService } from 'services/Supplies';
import { default as CompaniesService } from 'services/Companies';

const TableResponsive = styled.div` 
	@media (min-width: 992px) {
		overflow-x: visible;
	}
`;

const Table = styled.table`
	tr {

		th {

			&:nth-child(1) {
			}

			&:nth-child(2) {
				width: 20px;
			}

			&:nth-child(3) {
				width: 120px;
			}

			&:nth-child(4) {
				width: 120px;
			}

			&:nth-child(5) {
				width: 50px;
			}

			&:nth-child(6) {
				width: 50px;
			}
		}
	}
`;

const Filters = styled.div`
	display: flex;
	justify-content: end;

	@media (max-width: 1200px) {
		justify-content: center;
		flex-direction: column;

		& > *,
		.empowered-selector {
			width: 100% !important;
			display: block !important;
		}

		& > * {
			margin-bottom: 10px;
		}
	}
`;

const DateDropDownWrapper = styled.div` 
	padding: 0;
	min-height: 0;
	width: 192px;
	display: inline-block;

	& > .date-dropdown {
		display: block;

		& > .dropdown {
			width: 100%;
			display: block !important;

			& > .btn {
				padding: 0;
				width: 100% !important;
				display: flex;
				align-items: center;
				padding: 0 5px;

				small {
					margin-left: auto;
				}
			}
		}
	}
`;

const EmpoweredSelectorWrapper = styled.div` 
	margin-right: 10px;

	.empowered-selector {
		width: 200px;
		
		.empowered-selector_label {
			height: 28px;
			white-space: nowrap;
			overflow: hidden;

			&:hover {
				background: white;
			}

			.remove-icon {
				background: white;
				border-radius: 50%;
			}
		}
	}
`;

const HeaderCompanyEmpoweredSelector = styled.div`
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	width: 200px;
	border-right: 1px solid var(--bs-gray-500);
	
	.empowered-selector {

		.empowered-selector_label {
			border: 0;
			background: white;
		}
	}
`;

const HeaderButtonsTab = styled.div` 
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;

	a {
		display: flex;
		color: white;
		text-decoration: none;
		padding: 0px 10px;
		width: 50%;
		height: 100%;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;

		&.active {
			background: #65aa8e;
		}

		&:hover:not(.active) {
			text-decoration: underline;
		}
	}
`;

let axiosCancelToken = null;

export default function Supplies(props) {
	const type = props.type;

	const [queryParams] = useSearchParams();

	let [forceReload, setForceReload] = useState(null);
	let [supplies, setSupplies] = useState([]);
	let [sortDirection, setSortDirection] = useState('desc');
	let [sortField, setSortField] = useState('asterisk_date');
	let [detailsOpened, setDetailsOpened] = useState([]);
	let [details, setDetails] = useState({});
	let [station, setStation] = useState(null);
	let [company, setCompany] = useState(null);
	let [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
	let [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setSupplies({...supplies, data: undefined});
		_setPage(page);
	}

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	const toggleDetails = (rowId) => {
		if ( detailsOpened.includes(rowId) ) {
			detailsOpened.splice(detailsOpened.indexOf(rowId), 1);
			setDetailsOpened([...detailsOpened]);

			// Remove details
			delete details[rowId];
			setDetails({...details});
		} else {
			setDetailsOpened([...detailsOpened, rowId]);
		}
	}

	const setDatesRange = (range) => {
		setPage(1);
		setDateFrom(range.from);
		setDateTo(range.to);
	}

	const loadStations = (input, callback, args) => {
		// TODO from service
		axios.get('/api/stations/list', {
			params: {
				search: input,
				no_paginate: true,
				company_code: args.company_code
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});

			callback(formatted);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	
	}

	const loadCompanies = async (input, callback) => {
		let companies = await CompaniesService.list({no_paginate: true, search: input});

		let formatted = companies.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});

		callback(formatted);
	}

	const openChat = (e, station_id) => {
		e.stopPropagation();

		const event = new CustomEvent('open-chat', {detail: {stationId: station_id, channel: 'supplies'}});
		document.dispatchEvent(event);
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		let getSupplies = async () => {
			let supplies = await SuppliesService.getOrders({
				page: page,
				sort: sortField,
				direction: sortDirection,
				date_from: dateFrom,
				date_to: dateTo,
				station_id: station?.id,
				company_id: company?.id,
				type: type,
			});
			setSkeletonRows(supplies?.data?.length);
			setSupplies({...supplies});
		}
		getSupplies();

		let reloadInterval = setInterval(() => {
			getSupplies();
		}, 5000);

		return function cleanup() {
			clearInterval(reloadInterval);
		}
	}, [forceReload, page, sortField, sortDirection, station, company, dateFrom, dateTo, type]);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader style={{overflowX: 'visible', overflowY: 'visible'}}>
					<span className="header-column header-title">Suministros</span>
					<HeaderCompanyEmpoweredSelector>
						<EmpoweredSelector
							load={(input, callback) => loadCompanies(input, callback)}
							onChange={(value) => setCompany(value)}
							timeout={200}
							label={company?.name}
							value={company?.id ?? null}
							placeholder="EMPRESA"
							noRemovable={false}
							noSearcheable={true}
						/>
					</HeaderCompanyEmpoweredSelector>
					<span className="header-column header-icon-end">
						<i className="bi bi-printer text-plenoil-brown-light"></i>
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-brown-light text-white">
					<div className="row">
						<div className="col-md-3 position-relative">
							<HeaderButtonsTab>
								<NavLink to="/supplies/station">estaciones</NavLink>
								<NavLink to="/supplies/wash">lavaderos</NavLink>
							</HeaderButtonsTab>
						</div>
						<Filters className="col-md-9 py-2">
							<NavLink to="/supplies/allowed-codes/station" className="btn btn-light btn-sm me-2"><i className="bi bi-upc-scan"></i> códigos estación</NavLink>
							<NavLink to="/supplies/allowed-codes/wash" className="btn btn-light btn-sm me-2"><i className="bi bi-upc-scan"></i> códigos lavadero</NavLink>

							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback, args) => loadStations(input, callback, args)}
									args={{company_code: company?.code}}
									onChange={(value, text) => setStation(value)}
									timeout={200}
									label={station?.name}
									value={station?.id ?? null}
									placeholder="Estación"
								/>
							</EmpoweredSelectorWrapper>

							<DateDropDownWrapper className="form-control form-control-sm me-2">
								<DateDropdown 
									defaultPeriod={'today'} 
									onChange={(period, range) => setDatesRange(range)}
									onlyDates={true}
								/>
							</DateDropDownWrapper>
						</Filters>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<TableResponsive className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'station.name'} onClick={() => sortTableClick('station.name')}>Estación</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'asterisk_date'} onClick={() => sortTableClick('asterisk_date')}><i className="bi bi-asterisk"></i></ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'order.status'} onClick={() => sortTableClick('order.status')}>Estado</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'created_at'} onClick={() => sortTableClick('created_at')}>Fecha</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'id'} onClick={() => sortTableClick('id')}>Pedido Nº</ThSortable>
								<th>Chat</th>
							</tr>
						</thead>
						<tbody>
							{ supplies.data?.map((el, idx) => {
								return (
									<React.Fragment key={el.id}>
										<tr className={'has-details ' + (detailsOpened.includes(el.id) ? 'opened' : '')} onClick={() => toggleDetails(el.id)}>
											<td>{el.station?.name}</td>
											<td>
												{el.has_send_incidences && <span className="text-danger"><i className="bi bi-asterisk"></i></span>}
											</td>
											<td>
												{el.status === 0 && <span className="badge bg-secondary">pendiente</span>}
												{el.status === 1 && <span className="badge bg-success">aprobado</span>}
												{el.status === -1 && <span className="badge bg-danger">cancelado</span>}
											</td>
											<td>{moment(el.created_at).format('DD-MM-YYYY')}</td>
											<td>{el.id}</td>
											<td className={'chat ' + (!el.pending_chat_messages_count ? 'disabled' : '')}>
												<button className="btn btn-link btn-sm text-plenoil-brown-light" onClick={(e) => openChat(e, el.station_id)}><i className={'bi bi-chat-right' + (el.pending_chat_messages_count ? '-fill' : '')}></i></button>
											</td>
										</tr>
										{ detailsOpened.includes(el.id) &&
											<Details 
												id={el.id} 
												forceReload={() => setForceReload(Math.random())}
											/>
										}
									</React.Fragment>
								)
							}) }

							{ supplies.data && !supplies.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ supplies.data === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
						</tbody>
					</Table>
				</TableResponsive>
			</div>

			<div className="col-md-12 text-end mb-3">
				<div className="d-inline-block">
					<Paginator
						min={1}
						current={supplies?.current_page}
						max={supplies?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</div>
			</div>
		</LoggedLayout>
	);
}