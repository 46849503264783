import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams, NavLink } from "react-router-dom";
import LoggedLayout from '../../layouts/LoggedLayout';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import PageHeader from 'components/PageHeader';
import Paginator from 'components/Paginator';
import ThSortable from 'components/ThSortable';
import TrSkeleton from 'components/TrSkeleton';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as StationsService } from 'services/Stations';
import { default as SuppliesService } from 'services/Supplies';
import { default as CompaniesService } from 'services/Companies';
import ConfigModal from './ConfigModal';

const TableResponsive = styled.div` 
	@media (min-width: 992px) {
		overflow-x: visible;
	}
`;

const Table = styled.table`
	tr {

		th {

			&:nth-child(1) {
			}

			&:nth-child(2) {
				width: 120px;
			}

			&:nth-child(3) {
				width: 120px;
			}

			&:nth-child(4) {
				width: 50px;
			}

			&:nth-child(5) {
				width: 50px;
			}
		}

		td {

			&.next_planning {
				position: relative;

				i {
					font-size: 10px;
					position: absolute;
					top: calc(50% - 6px);
					right: 2px;
					cursor: pointer;

					&:hover {
						transform: scale(1.3);
					}
				}

				input[type=date] {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 0;
					width: 85%;
					font-size: 12px;
					background: transparent;
				}
			}
		}
	}
`;

const Filters = styled.div`
	display: flex;
	justify-content: end;

	@media (max-width: 1200px) {
		justify-content: center;
		flex-direction: column;

		& > *,
		.empowered-selector {
			width: 100% !important;
			display: block !important;
		}

		& > * {
			margin-bottom: 10px;
		}
	}
`;

const EmpoweredSelectorWrapper = styled.div` 
	margin-right: 10px;

	.empowered-selector {
		width: 200px;
		
		.empowered-selector_label {
			height: 28px;
			white-space: nowrap;
			overflow: hidden;

			&:hover {
				background: white;
			}

			.remove-icon {
				background: white;
				border-radius: 50%;
			}
		}
	}
`;

const HeaderCompanyEmpoweredSelector = styled.div`
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	width: 200px;
	border-right: 1px solid var(--bs-gray-500);
	
	.empowered-selector {

		.empowered-selector_label {
			border: 0;
			background: white;
		}
	}
`;

const HeaderButtonsTab = styled.div` 
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;

	a {
		display: flex;
		color: white;
		text-decoration: none;
		padding: 0px 10px;
		width: 50%;
		height: 100%;
		align-items: center;
		justify-content: center;
		text-transform: uppercase;

		&.active {
			background: #65aa8e;
		}

		&:hover:not(.active) {
			text-decoration: underline;
		}
	}
`;

let axiosCancelToken = null;

export default function Supplies(props) {
	const type = props.type;

	const [queryParams] = useSearchParams();

	let [planning, setPlanning] = useState([]);
	let [sortDirection, setSortDirection] = useState('asc');
	let [sortField, setSortField] = useState('next_planning');
	let [station, setStation] = useState(null);
	let [company, setCompany] = useState(null);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	let setPage = (page) => {
		setPlanning({...planning, data: undefined});
		_setPage(page);
	}
	let [editingNextPlanning, _setEditingNextPlanning] = useState([]);
	let setEditingNextPlanning = (arr) => {
		_setEditingNextPlanning(arr);
		editingRef.current = arr.length > 0 ? true: false;
	};
	let editingRef = useRef(false);
	let [globalConfigModalOpened, setGlobalConfigModalOpened] = useState(false);
	let [stationConfigModalOpened, setStationConfigModalOpened] = useState(null);

	const sortTableClick = (field) => {
		if ( !field ) return;
		if ( field === sortField ) setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		setSortField(field);
	};	

	const loadStations = async (input, callback, args) => {
		let stations = await StationsService.getList({no_paginate: true, search: input, company_code: args.company_code});

		let formatted = stations.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});

		callback(formatted);
	}

	const loadCompanies = async (input, callback) => {
		let companies = await CompaniesService.list({no_paginate: true, search: input});

		let formatted = companies.map((el) => {
			return {
				value: el, 
				label: el.name
			};
		});

		callback(formatted);
	}

	const openChat = (e, station_id) => {
		e.stopPropagation();

		const event = new CustomEvent('open-chat', {detail: {stationId: station_id, channel: 'supplies'}});
		document.dispatchEvent(event);
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		let getPlanning = async () => {
			let planning = await SuppliesService.getPlanning({
				page: page,
				sort: sortField,
				direction: sortDirection,
				station_id: station?.id,
				company_id: company?.id,
				type: type
			});
			setSkeletonRows(planning?.data?.length);
			setPlanning({...planning});
		}
		getPlanning();

		let reloadInterval = setInterval(() => {
			if ( editingRef.current ) return; // Prevent reload if editing

			getPlanning();
		}, 5000);

		return function cleanup() {
			clearInterval(reloadInterval);
		}
	}, [page, sortField, sortDirection, station, company, type]);

	const toggleNextPlanningInput = (idx) => {
		let newEditingNextPlanning = [...editingNextPlanning];
		if ( newEditingNextPlanning.includes(idx) ) newEditingNextPlanning.splice(newEditingNextPlanning.indexOf(idx), 1);
		else newEditingNextPlanning.push(idx);
		setEditingNextPlanning(newEditingNextPlanning);
	}

	const setNextPlanning = (idx, date) => {
		let newPlanning = [...planning.data];
		newPlanning[idx].next_planning = date;
		setPlanning((prev) => ({...prev, data: newPlanning}));
	}

	const saveNextPlanning = async (idx) => {
		let stationId = planning.data[idx].id;
		let next_planning = planning.data[idx].next_planning;

		let result = await SuppliesService.setNextPlanning(stationId, type, next_planning);
		if ( !result.status ) {
			toast.error('Error al cambiar la fecha de planificación');
		}
 
		toggleNextPlanningInput(idx);
	}

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<PageHeader style={{overflowX: 'visible', overflowY: 'visible'}}>
					<span className="header-column header-title">Suministros Planificación</span>
					<HeaderCompanyEmpoweredSelector>
						<EmpoweredSelector
							load={(input, callback) => loadCompanies(input, callback)}
							onChange={(value) => setCompany(value)}
							timeout={200}
							label={company?.name}
							value={company?.id ?? null}
							placeholder="EMPRESA"
							noRemovable={false}
							noSearcheable={true}
						/>
					</HeaderCompanyEmpoweredSelector>
					<button className="btn btn-sm" onClick={() => setGlobalConfigModalOpened(true)}><i className="bi bi-gear"></i> Planificación general</button>
					<span className="header-column header-icon-end">
						<i className="bi bi-printer text-plenoil-brown-light"></i>
					</span>
				</PageHeader>
			</div>

			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-brown-light text-white">
					<div className="row">
						<div className="col-md-3 position-relative">
							<HeaderButtonsTab>
								<NavLink to="/supplies/planning/station">estaciones</NavLink>
								<NavLink to="/supplies/planning/wash">lavaderos</NavLink>
							</HeaderButtonsTab>
						</div>
						<Filters className="col-md-9 py-2">
							<EmpoweredSelectorWrapper>
								<EmpoweredSelector
									load={(input, callback, args) => loadStations(input, callback, args)}
									args={{company_code: company?.code}}
									onChange={(value, text) => setStation(value)}
									timeout={200}
									label={station?.name}
									value={station?.id ?? null}
									placeholder="Estación"
								/>
							</EmpoweredSelectorWrapper>
						</Filters>
					</div>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<TableResponsive className="table-responsive shadow-sm rounded">
					<Table className="table table-sm table-bordered table-striped table-custom bg-white mb-0">
						<thead>
							<tr>
								<ThSortable direction={sortDirection} active={sortField === 'station_name'} onClick={() => sortTableClick('station_name')}>Estación</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'next_planning'} onClick={() => sortTableClick('next_planning')}>Próxima solicitud</ThSortable>
								<ThSortable direction={sortDirection} active={sortField === 'last_planning'} onClick={() => sortTableClick('last_planning')}>Última solicitud</ThSortable>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ planning.data?.map((el, idx) => {
								return (
									<React.Fragment key={el.id}>
										<tr>
											<td>
												<div className="d-flex justify-content-between">
													<span>{el.station_name}</span>
												</div>
											</td>
											<td className="next_planning">
												{ !editingNextPlanning.includes(idx) ?
													<>
														{el.next_planning ? moment(el.next_planning).format('DD-MM-YYYY') : ''}
														<i className="bi bi-pencil" onClick={() => toggleNextPlanningInput(idx)}></i>
													</>
													:
													<>
														<input type="date" value={el.next_planning ? moment(el.next_planning).format('YYYY-MM-DD') : ''} onChange={(e) => setNextPlanning(idx, e.target.value)} />
														<i className="bi bi-check-circle-fill text-success" onClick={() => saveNextPlanning(idx)}></i>
													</>
												}

											</td>
											<td>{el.last_planning ? moment(el.last_planning).format('DD-MM-YYYY') : ''}</td>
											<td>
												<button className="btn btn-sm" onClick={(e) => setStationConfigModalOpened(el)}><i className="bi bi-gear"></i></button>
											</td>
											<td className={'chat ' + (!el.pending_chat_messages_count ? 'disabled' : '')}>
												<button className="btn btn-link btn-sm text-plenoil-brown-light" onClick={(e) => openChat(e, el.id)}><i className={'bi bi-chat-right' + (el.pending_chat_messages_count ? '-fill' : '')}></i></button>
											</td>
										</tr>
									</React.Fragment>
								)
							}) }

							{ planning.data && !planning.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

							{ planning.data === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
						</tbody>
					</Table>
				</TableResponsive>
			</div>

			<div className="col-md-12 text-end mb-3">
				<div className="d-inline-block">
					<Paginator
						min={1}
						current={planning?.current_page}
						max={planning?.last_page}
						changeCallback={(page) => setPage(page)}
					/>
				</div>
			</div>
			{ globalConfigModalOpened &&
				<ConfigModal
					type={type}
					closeCallback={() => setGlobalConfigModalOpened(false)}
				/>
			}
			{ stationConfigModalOpened &&
				<ConfigModal
					type={type}
					station={stationConfigModalOpened}
					closeCallback={() => setStationConfigModalOpened(false)}
				/>
			}
		</LoggedLayout>
	);
}