import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import EmpoweredSelector from 'components/EmpoweredSelector';
import axios from 'axios';
import { hasCapability } from 'helpers/user';

const Station = styled.div`
	background: white;
	cursor: pointer;
	display: inline-flex;
	margin: 2px 5px;
	border-radius: 3px;
	padding: 5px 10px;
	user-select: none;

	&:hover {
		position: relative;
		
		&:after {
			content: '×';
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			background: rgba(255,255,255,0.7);
			color: var(--bs-red);
			font-weight: bold;
			font-size: 30px;
		}
	}
`;

let axiosCancelToken = null;

export default function ZonesForm() {
	const navigate = useNavigate();
	const params = useParams();

	let [data, setData] = useState({
		name: '',
		middlewareStations: []
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		if ( params.id ) {
			const getData = async () => {
				return axios.get('api/zones/get/' + params.id, {
					cancelToken: axiosCancelToken.token
				}).then((response) => {
			    	setData(response.data);
			  	}).catch((error) => {
					if ( axios.isCancel(error) ) return;
				});
			}
			getData();
		}
	}, [params.id]);

	const loadStations = (input, callback) => {
		axios.get('/api/middleware-stations/list', {
			params: {
				search: input
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el.id, 
					label: el.name
				};
			});
			callback(formatted);
	  	}).catch((error) => {
			console.log(error);
			
			if ( axios.isCancel(error) ) return;

		});	
	}

	const addStation = (id, name) => {
		let newStations = [...data.middlewareStations];

		let idx = newStations.findIndex((el) => el.id === id);
		if ( idx !== -1 ) return;

		newStations.push({
			id: id,
			name: name
		});
		setData({...data, middlewareStations: newStations});
	}

	const removeStation = (id) => {
		let newStation = [...data.middlewareStations];
		let idx = newStation.findIndex((el) => el.id === id);
		newStation.splice(idx, 1);
		setData({...data, middlewareStations: newStation});
	}

	const setName = (name) => {
		setData({...data, name: name});
	}

	const saveData = async () => {
		setErrors({});

		let dataToSend = {
			...data,
			middlewareStations: data.middlewareStations.map((el) => el.id)
		};

		await axios.post('api/zones/' + (params.id ? 'edit/' + params.id : 'add'), dataToSend, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const deleteZone = () => {
		const c = window.confirm('¿Seguro que quieres eliminar esta zona?');
		if ( !c ) return false;

		axios.delete('api/zones/delete/' + data.id, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Zona eliminada');
			navigate(-1);
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al eliminar');
		});	
	}

	return (
		<LoggedLayout>
			<div className="row flex-column align-items-center">
				<div className="col-md-6 mb-3">
					<NavLink to="/zones" className="btn btn-sm btn-secondary float-end">Volver</NavLink>

					<h4 className="mb-0">{ data.id ? 'Modificar' : 'Nueva' } zona</h4>
				</div>

				<div className="col-md-6 mb-3">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-md-8">
									<div className="mb-2">
										<label htmlFor="name">Nombre</label>
										<input type="text" id="name" className="form-control form-control-sm" onChange={(e) => setName(e.target.value)} value={data.name} />
										{ errors.name &&
											<div className="invalid-feedback d-block">{ errors.name[0] }</div>
										}
									</div>
								</div>
							</div>
							<div className="mb-2">
								<label htmlFor="stations">Estaciones</label>
								<div className="border rounded bg-light p-2">
									<div className="row justify-content-end">
										<div className="col-md-6">
											<EmpoweredSelector
												load={(input, callback) => loadStations(input, callback)}
												onChange={(value, text) => addStation(value, text)}
												timeout={500}
												label="Estaciones"
												placeholder="Estaciones"
												value={null}
												except={data.middlewareStations?.map((el) => el.id)}
											/>
										</div>
									</div>
									<div className="stations-wrapper mt-2">
										{ data.middlewareStations?.map((el) => {
											return  <Station key={el.id} onClick={() => removeStation(el.id)}>
														{ el.name }
													</Station>;
										}) }
									</div>
									{ errors.middlewareStations &&
										<div className="invalid-feedback d-block">{ errors.middlewareStations[0] }</div>
									}
								</div>
							</div>
						</div>
						<div className="card-footer d-flex justify-content-between">
							{ (data.id && hasCapability('probes', 'delete')) &&
								<button className="btn btn-sm btn-link text-danger" onClick={() => deleteZone()}>Eliminar</button>
							}

							{ ((!data.id && hasCapability('probes', 'add')) || (data.id && hasCapability('probes', 'edit'))) &&
								<button className="btn btn-sm btn-plenoil-primary" onClick={() => saveData()}>Guardar</button>
							}
						</div>
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


