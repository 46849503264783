import { useEffect, useState } from 'react';
import axios from 'axios';
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import LoggedLayout from 'layouts/LoggedLayout';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { hasCapability } from 'helpers/user';

let axiosCancelToken = null;

export default function TankerTrucksForm() {
	const navigate = useNavigate();

	let [data, setData] = useState({
		station: null
	});
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const saveData = async () => {
		setErrors({});

		data.manual = true; // Set manual on DB

		await axios.post('api/tankertrucks/add', data, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Datos guardados');
			navigate('/tankertrucks');
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			if ( error.response.data.errors ) setErrors(error.response.data.errors);
			toast.error('Ha ocurrido un error al guardar');
		});	
	}

	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const loadStations = (input, callback) => {
		axios.get('/api/stations/list', {
			params: {
				search: input,
				no_paginate: true
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			let formatted = response.data.map((el) => {
				return {
					value: el, 
					label: el.name
				};
			});

			callback(formatted);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	
	}

	const selectStation = (value) => {
		setData({...data, station: value, id: value?.id});
	}

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<div className="bg-plenoil-primary text-white text-uppercase p-2">
					Envío manual
					<NavLink to="/tankertrucks" className="btn btn-sm btn-light p-0 px-2 float-end">Volver</NavLink>
				</div>
			</div>

			<div className="col-md-12 mb-3">
				<div className="card shadow-sm">
					<div className="card-body">
						<div className="row">
							<div className="col-md-3">
								<div className="mb-2">
									<label>Estación</label>
									<EmpoweredSelector
										load={(input, callback) => loadStations(input, callback)}
										onChange={(value, text) => selectStation(value)}
										timeout={200}
										label={data.station?.name}
										value={data.station?.id ?? null}
									/>
									{ errors.id &&
										<div className="invalid-feedback d-block">{ errors.id[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Día</label>
									<input type="date" className="form-control form-control-sm" onChange={(e) => setDataField('date', e.target.value)} value={data.date ?? ''} />
									{ errors.date &&
										<div className="invalid-feedback d-block">{ errors.date[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Hora llegada inicio</label>
									<input type="time" className="form-control form-control-sm" onChange={(e) => setDataField('hour_expected_init', e.target.value)} value={data.hour_expected_init ?? ''} />
									{ errors.hour_expected_init &&
										<div className="invalid-feedback d-block">{ errors.hour_expected_init[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Hora llegada fin</label>
									<input type="time" className="form-control form-control-sm" onChange={(e) => setDataField('hour_expected_end', e.target.value)} value={data.hour_expected_end ?? ''} />
									{ errors.hour_expected_end &&
										<div className="invalid-feedback d-block">{ errors.hour_expected_end[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Litros diesel</label>
									<input type="number" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('diesel_liters', e.target.value)} value={data.diesel_liters ?? ''} />
									{ errors.diesel_liters &&
										<div className="invalid-feedback d-block">{ errors.diesel_liters[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Litros gasolina</label>
									<input type="number" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('sp95_liters', e.target.value)} value={data.sp95_liters ?? ''} />
									{ errors.sp95_liters &&
										<div className="invalid-feedback d-block">{ errors.sp95_liters[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Transportista</label>
									<input type="text" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('carrier', e.target.value)} value={data.carrier ?? ''} />
									{ errors.carrier &&
										<div className="invalid-feedback d-block">{ errors.carrier[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-3">
								<div className="mb-2">
									<label>Código del ransportista</label>
									<input type="text" className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('carrier_code', e.target.value)} value={data.carrier_code ?? ''} />
									{ errors.carrier_code &&
										<div className="invalid-feedback d-block">{ errors.carrier_code[0] }</div>
									}
								</div>
							</div>
							<div className="col-md-12">
								<div className="mb-2">
									<label>Observaciones</label>
									<textarea className="form-control form-control-sm no-arrows" onChange={(e) => setDataField('observations', e.target.value)} value={data.observations ?? ''}></textarea>
									{ errors.observations &&
										<div className="invalid-feedback d-block">{ errors.observations[0] }</div>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="card-footer d-flex justify-content-between">
						<span>&nbsp;</span>

						{ hasCapability('tankertrucks', 'add') &&
							<button className="btn btn-sm btn-plenoil-blue-light" onClick={() => saveData()}>Guardar</button>
						}
					</div>
				</div>
			</div>
		</LoggedLayout>
	);
}


