import { useEffect, useState } from 'react';
import LoggedLayout from 'layouts/LoggedLayout';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import GlobalConfigModal from './GlobalConfigModal';

const Table = styled.table`

	td, th {
		
		&:nth-child(1) {

		}

		&:nth-child(2) {
			width: 200px;
		}

		&:nth-child(3) {
			width: 200px;
		}

		&.warning {
			background: var(--plenoil-red);
			color: white;
		}

		& > .date {
			font-size: 12px;
		}

		& > .liters {
			font-size: 12px;
		}

		& > .no-info {
			display: block;
			font-size: 12px;
			line-height: 12px;
			color: lightgray;
		}
	}
`;

let axiosCancelToken = null;

export default function ProbesWarnings() {
	let [stations, setStations] = useState([]);
	let [forcedProbesLoading, setForcedProbesLoading] = useState({});
	const [globalConfigModalOpened, setGlobalConfigModalOpened] = useState(null);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getStations = async () => {
			return axios.get('api/probes-warnings/list', {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setStations(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}
		getStations();

		let interval = setInterval(() => {
			getStations();
		}, 10000);

		return function cleanup() {
			clearInterval(interval);
		}
	}, []);

	const forceProbe = (station) => {
		setForcedProbesLoading((prev) => ({...prev, [station.id]: true}));

		axios.get('api/probes/get/' + station.octan_id, {
			params: {},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			
		}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		}).then(() => {
			setForcedProbesLoading((prev) => {
				delete prev[station.id];
				return prev;
			});
		});
	}

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<button className="btn btn-secondary btn-sm p-1 float-end" onClick={() => setGlobalConfigModalOpened(true)}><i className="bi bi-pencil"></i> Configuración</button>
				<h4 className="mb-0">Avisos de sondas</h4>
			</div>

			<div className="col-md-12 mb-3">
				<div className="table-responsive">
					<Table className="table table-bordered bg-white">
						<thead>
							<tr className="bg-plenoil-primary text-white">
								<th>Estación</th>
								<th>GOA</th>
								<th>SP95</th>
							</tr>
						</thead>
						<tbody>
							{ stations.map((el, idx) => {
								return (
									<tr key={idx}>
										<td>
											{el.name}

											<button 
												className="btn btn-sm btn-light p-0 float-end" 
												onClick={() => forcedProbesLoading.hasOwnProperty(el.id) ? null : forceProbe(el)}
											>
												{forcedProbesLoading.hasOwnProperty(el.id) ? 'forzando sonda...' : 'forzar sonda'}
											</button>
										</td>
										<td className={el.probewarning?.goa_warning_at ? 'warning' : ''}> 
											{el.probewarning?.goa_lastcheck_at ?
												<div className="date">
													{moment(el.probewarning.goa_lastcheck_at).format('DD-MM-YYYY HH:mm')}
												</div>
												:
												<small className="no-info">no hay información disponible</small>
											}
											{el.probewarning?.goa_liters &&
												<div className="liters">
													{el.probewarning?.goa_liters}&nbsp;litros
												</div>
											}
											{el.probewarning?.goa_percent  &&
												<div className="progress">
													<div className="progress-bar" style={{width: (el.probewarning?.goa_percent ?? 0) + '%'}}>{el.probewarning?.goa_percent ?? '-'} %</div>
												</div>
											}
										</td>
										<td className={el.probewarning?.sp95_warning_at ? 'warning' : ''}>
											{el.probewarning?.sp95_lastcheck_at ?
												<div className="date">
													{moment(el.probewarning.sp95_lastcheck_at).format('DD-MM-YYYY HH:mm')}
												</div>
												:
												<small className="no-info">no hay información disponible</small>
											}
											{el.probewarning?.sp95_liters &&
												<div className="liters">
													{el.probewarning?.sp95_liters}&nbsp;litros
												</div>
											}
											{el.probewarning?.sp95_percent  &&
												<div className="progress">
													<div className="progress-bar" style={{width: el.probewarning?.sp95_percent + '%'}}>{el.probewarning?.sp95_percent ?? '-'} %</div>
												</div>
											}
										</td>
									</tr>
								)
							}) }
							{ !stations.length && <tr key="empty"><td colSpan="100%">No hay estaciones</td></tr> }
						</tbody>
					</Table>
				</div>
			</div>
			{globalConfigModalOpened &&
				<GlobalConfigModal 
					closeCallback={() => {
						setGlobalConfigModalOpened(null);
					}}
				/>
			}
		</LoggedLayout>
	);
}


