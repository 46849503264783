import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { loader } from 'helpers/generic';
import Highlight from 'react-highlight';
import '/node_modules/highlight.js/styles/dark.css';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	&.modal {

		.modal-header {

			h5 {
				font-size: 15px !important;
				line-height: 15px !important;
			}
		}

		table {
			user-select: none;

			tr {
				td {
					font-weight: normal !important;
					font-size: 11px;
				}

				td, th {
					text-align: left;

					&:nth-child(1) {
						width: 50px;
					}

					&:nth-child(2) {
						width: 50px;
					}

					&:nth-child(3) {
						width: 50px;
					}

					&:nth-child(4) {
						width: 20px;
					}

					&:nth-child(5) {
						width: auto;
					}
				}

				&.has-additional {
					cursor: pointer;

					&:hover {
						background: var(--bs-light);
					}
				}

				&.additional {
					td {
						position: relative;
						width: 200px;
						height: 300px;

						.code-wrapper {
							position: absolute;
							height: 100%;
							width: 100%;
							top: 0;
							left: 0;

							pre {
								height: 100%;
								margin: 0;

								code {
									width: 100%;
									height: 100%;
									text-align: left !important;
								}
							}
						}
					}
				}
			}
		}
	}

	#loader {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		width: 100%;
		height: 100%;
		user-select: none;

		img {
			width: 200px !important;
			height: auto !important;
			user-select: none;
		}
	}
`;

const Table = styled.table`
	font-size: 12px;
	font-weight: normal;

	td {
		
	}
`;

let axiosCancelToken = null;

const CarrierSendLogsModal = React.memo((props) => {
	const modalRef = useRef(null);

	const id = props.id;
	const closeCallback = props.closeCallback;

	const [data, setData] = useState([]);
	const [additionalOpened, setAdditionalOpened] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		const loadHistorial = async () => {
			setLoading(true);

			await axios.get('/api/tankertrucks/get-carrier-send-logs/' + id, {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setData([...response.data]);
			}).catch((error) => {			
				if ( axios.isCancel(error) ) return;
			});	

			setLoading(false);
		}
		loadHistorial();
	}, [id]);

	const closeModal = () => {
		// Hide modal
		Modal.getInstance(modalRef.current).hide();
		props.closeCallback();
	}

	const toggleAdditionalOpened = (idx) => {
		let newAdditionalOpened = {...additionalOpened};
		if ( newAdditionalOpened[idx] ) delete newAdditionalOpened[idx];
		else newAdditionalOpened[idx] = true;
		setAdditionalOpened(newAdditionalOpened);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			{ loading && loader }
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Historial Pegasus</h5>
						<button type="button" className="btn-close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
						{ data.length > 0 && 
							<Table className="table table-sm table-bordered m-0">
								<thead>
									<tr>
										<th>Día</th>
										<th>Hora</th>
										<th>Código</th>
										<th>#</th>
										<th>Mensaje</th>
									</tr>
								</thead>
								<tbody>
									{data && data.map((el, idx) => {
										let has_additional = false;
										if ( Array.isArray(el.additional) && el.additional.length > 0 ) has_additional = true;
										else if ( Object.keys(el.additional).length > 0 ) has_additional = true;

										return (
											<React.Fragment key={idx}>
												<tr className={has_additional ? 'has-additional' : ''} onClick={() => toggleAdditionalOpened(idx)}>
													<td>{moment(el.created_at).format('DD-MM-YYYY')}</td>
													<td>{moment(el.created_at).format('HH:mm:ss')}</td>
													<td>{el.code}</td>
													<td>{has_additional && <i className="bi bi-activity"></i>}</td>
													<td>{el.message}</td>
												</tr>
												{ (has_additional && additionalOpened[idx]) &&
													<tr className="additional">
														<td colSpan="100%">
															<div class="code-wrapper">
																<Highlight className="language-json">{JSON.stringify(el.additional, null, 2)}</Highlight>
															</div>	
														</td>
													</tr>
												}
											</React.Fragment>
										)
									})}
								</tbody>
							</Table>
						}
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}, (a, b) => {
	return true;
})

export default CarrierSendLogsModal;