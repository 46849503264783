import { useEffect } from 'react';
import styled from 'styled-components';
import { jsonToArray } from 'helpers/generic';
import Speedometer from './Speedometer';
import Details from './Details';
import { loader } from 'helpers/generic';
import { assets } from 'helpers/generic';

const ModalStyled = styled.dialog`
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    background: rgba(255,255,255,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: scroll;

    .modal-inner {
        margin: auto;
        width: 70%;
        border-radius: 25px;
        background: white;
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
        overflow: hidden;

        .station-name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 20px;
            font-weight: bold;
            padding: 10px 20px;
            margin-top: 40px;
            background: var(--plenoil-orange);
            color: white;
            text-transform: uppercase;
            font-size: 20px;

            button {
                color: var(--plenoil-black);
                text-decoration: none;
                text-transform: uppercase;
                font-size: 12px;
            }
        }

        .station-data {
            position: relative;
            
            .loading {
                z-index: 99999;
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                font-weight: bold;
                background: rgba(255,255,255,0.5);
            }

            .modal-oil-row {
                display: flex;
                width: 100%;
                border-bottom: 3px solid white;
                background: #fffaf6;
                padding: 0 20px;
                
                .total-wrapper {
                    width: 30%;
                    height: 100%;
                    border-right: 1px solid black;
                    padding-bottom: 20px;
                    padding-right: 20px;

                    .oil-name {
                        font-size: 20px;
                    }

                    .total {
                        text-align: center;
                        font-size: 25px;
                        line-height: 25px;
                        margin-top: 5px;

                        span {
                            display: block;
                            font-size: 16px;
                            margin-top: -5px;
                        }
                    }

                    .total-speedometer {
                        position: relative;

                        .h2o_warning {
                            position: absolute;
                            right: 0px;
                            top: -10px;
                            height: 30px;
                        }
                    }
                }

                .tanks-wrapper {
                    width: 70%;
                    padding-bottom: 20px;

                    .tank-row {
                        display: inline-flex;
                        flex-direction: column;
                        width: 33%;
                        height: 100%;
                        padding: 10px 20px;

                        &:not(:last-child) {
                            border-right: 1px solid black;
                        }

                        .details {
                            padding: 10px;
                        }

                        .tank_name {
                            text-align: center;
                            text-transform: uppercase;

                            span {
                                display: block;
                                font-size: 9px;
                                line-height: 9px;
                            }
                        }
                    }
                }
            }
        }
    }
`;

const Modal = (props) => {
    let station = props.station;
    let tanks = props.tanks;
    let warningPercent = props.warningPercent;
    let totals = props.totals;
    let close = props.close;
    let loading = props.loading;
    let reload = props.reload;

    useEffect(() => {
        // Remove overflow from body and restore on cleanup
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);

    const closeModal = (e) => {
        if (e.target.id === 'station-probes-modal') {
            close();
        }
    }

    return (
        <ModalStyled id="station-probes-modal" onClick={closeModal}>
            <div className="modal-inner">
                <div className="station-name">
                    {station.name}
                    <button className="btn btn-link btn-sm p-0" onClick={() => close()}>cerrar</button>
                </div>
                <div className="station-data">
                    {loading && <div className="loading">{loader}</div>}
                    {jsonToArray(totals).map(el => {
                        return (
                            <div key={'cp' + el.key} className="modal-oil-row">
                                <div className="total-wrapper">
                                    <div className="oil-name">
                                        {el.value.name}
                                    </div>

                                    <div className="total">
                                        TOTAL
                                        <span>{el.value.name}</span>
                                    </div>

                                    <div className="total-speedometer">
                                        <Speedometer {...el.value} warningPercent={warningPercent} />
                                        {el.value.h2o_warning && <img src={assets('/img/iconoagua.svg')} alt="warning" className="h2o_warning" />}
                                    </div>
                                </div>
                                <div className="tanks-wrapper">
                                    {tanks.filter(tEl => tEl.codigo_producto === el.value.codigo_producto).map(tank => {
                                        return (
                                            <div className="tank-row" key={tank.tank_id}>
                                                <div className="details">
                                                    <Details 
                                                        {...tank} 
                                                        tankId={tank.tank_id} 
                                                        editable={true}
                                                        reload={reload ? reload : null}
                                                    />
                                                </div>
                                                <div className="tank_name">
                                                    Tanque {tank.num_tanque}
                                                    <span>{el.value.name}</span>
                                                </div>
                                                <div className="speedometer">
                                                    <Speedometer {...tank} color={el.value.color} warningPercent={warningPercent} />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </ModalStyled>
    );
}

export default Modal;