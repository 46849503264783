import { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import LoggedLayout from 'layouts/LoggedLayout';
import axios from 'axios';
import styled from 'styled-components';
import { hasCapability } from 'helpers/user';

const Table = styled.table`
	th,
	td {
		&:nth-child(3) {
			width: 20px;
			vertical-align: middle;
		}
	}
`;

let axiosCancelToken = null;

export default function Zones() {
	let [zones, setZones] = useState([]);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const getZones = async () => {
			return axios.get('api/zones/list', {
				params: {},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
		    	setZones(response.data);
		  	}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
		}

		getZones();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				{ hasCapability('probes', 'add') &&
					<NavLink to="/zones/add" className="btn btn-sm btn-plenoil-primary float-end">Añadir zona</NavLink>
				}
				<h4 className="mb-0">Listado de zonas</h4>
				<small>Las zonas se utilizan para agrupar estaciones por zonas de distribución</small>
			</div>

			<div className="col-md-12 mb-3">
				<div className="table-responsive">
					<Table className="table table-bordered bg-white">
						<thead>
							<tr className="bg-plenoil-primary text-white">
								<th>Nombre</th>
								<th>Estaciones</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{ zones.map((el, idx) => {
								return (
									<tr key={idx}>
										<td>{el.name}</td>
										<td>{ el.middlewareStations?.map((mEl, idx) => {
											return <div key={idx}>{ mEl.name }</div>;
										}) }</td>
										<td>
											<NavLink to={'/zones/edit/' + el.id}><i className="bi bi-pencil"></i></NavLink>
										</td>
									</tr>
								)
							}) }
							{ !zones.length && <tr key="empty"><td colSpan="100%">No hay zonas</td></tr> }
						</tbody>
					</Table>
				</div>
			</div>
		</LoggedLayout>
	);
}


