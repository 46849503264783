import { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment/min/moment-with-locales';
import styled from 'styled-components';
import { DayPicker } from 'react-day-picker';
import es from 'date-fns/locale/es';
import 'react-day-picker/dist/style.css';

moment.locale(navigator.userLanguage || navigator.language);

const DateDropdownStyled = styled.div`
	display: inline-block;

	.dropdown {
		.btn {
			color: ${props => props.color ?? 'inherit'};
		}

		.dropdown-menu {
			padding: 0;
			border-color: transparent;
			box-shadow: 0px 0px 10px rgba(1,1,1,0.2);
			z-index: 9999;

			&.start {
				left: 0;
			}

			&.end {
				right: 0;
			}

			@media (max-width: 768px) {
				position: fixed;
				top: 5%;
				left: 50% !important; 
				width: 295px !important;
				margin-left: -147.5px !important;
				max-height: 90%;
				overflow: auto;
			}
		}
	}
`;

const DropdownMenuInner = styled.div`
	display: flex;

	@media (max-width: 768px) {
		flex-direction: column;
	}
`;

const DropdownOptions = styled.div`
	background: var(--bs-gray-100);
`;

const DayPickerWrapper = styled.div`
	padding: 5px;
	.rdp {
		margin: 0;
		--rdp-accent-color: var(--bs-primary);

		.selected {
			background: var(--bs-primary);
		}
	}
`;

export const periods = {
	total: 		'Todo el periodo',
	yesterday: 	'Ayer',
	today: 		'Hoy',
	tomorrow: 	'Mañana',
	thisweek: 	'Esta semana',
	lastweek: 	'Semana pasada',
	thismonth: 	'Este mes',
	lastmonth: 	'Mes pasado',
	thisyear: 	'Este año',
	lastyear: 	'Año pasado',
	custom: 	'Rango personalizado'
};


export const getDatesRangeFromPeriod = (period, onlyDates = false) => {
	// Prepare dates
	let date_from = null;
	let date_to = null;

	switch(period) {
		case 'today': 
			date_from = moment().format('YYYY-MM-DD');
			date_to = moment().format('YYYY-MM-DD');
		break;
		case 'yesterday': 
			date_from = moment().subtract(1, 'days').format('YYYY-MM-DD');
			date_to = moment().subtract(1, 'days').format('YYYY-MM-DD');
		break;
		case 'tomorrow': 
			date_from = moment().add(1, 'days').format('YYYY-MM-DD');
			date_to = moment().add(1, 'days').format('YYYY-MM-DD');
		break;
		case 'thisweek': 
			date_from = moment().startOf('weeks').format('YYYY-MM-DD');
			date_to = moment().endOf('weeks').format('YYYY-MM-DD');
		break;
		case 'lastweek': 
			date_from = moment().subtract(1, 'weeks').startOf('weeks').format('YYYY-MM-DD');
			date_to = moment().subtract(1, 'weeks').endOf('weeks').format('YYYY-MM-DD');
		break;
		case 'thismonth': 
			date_from = moment().startOf('month').format('YYYY-MM-DD');
			date_to = moment().endOf('month').format('YYYY-MM-DD');
		break;
		case 'lastmonth': 
			date_from = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
			date_to = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
		break;
		case 'thisyear': 
			date_from = moment().startOf('year').format('YYYY-MM-DD');
			date_to = moment().endOf('year').format('YYYY-MM-DD');
		break;
		case 'lastyear': 
			date_from = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
			date_to = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
		break;
		default: // Total
			date_from = '2021-01-01';
			date_to = '3000-01-01';
	}

	if ( period.indexOf('custom_') !== -1 ) {
		let split = period.split('_');
		date_from = split[1];
		date_to = split[2];
	}

	if ( !onlyDates ) {
		date_from += ' 00:00:00';
		date_to += ' 23:59:59';
	}
	
	return {
		from: date_from,
		to: date_to
	};
}

export default function DateDropdown(props) {
	const element = useRef(null);

	const [opened, setOpened] = useState(false);
	const [period, setPeriod] = useState(props.defaultPeriod ?? 'today');
	const [range, setRange] = useState(null);

	const setPeriodClick = useCallback((e, period) => {
		if (e) e.preventDefault();

		let datesRangeFromPeriod = getDatesRangeFromPeriod(period, props.onlyDates);
		setPeriod(period.indexOf('custom_') !== -1 ? 'custom' : period);
		setOpened(false);
		if ( period.indexOf('custom_') === -1 ) setRange(null);

		if ( props.onChange ) props.onChange(period, datesRangeFromPeriod);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleDropdown = () => {
		setOpened(opened ? false : true);
	}

	useEffect(() => {
		if ( props.setPeriod === undefined ) return;
		setPeriod(null);
		setRange(null);
		setPeriodClick(null, props.setPeriod);
	}, [props.setPeriod, setPeriodClick]);

	useEffect(() => {
		let clickEventListener = (e) => {
			if ( element?.current === e.target || element?.current?.contains(e.target) ) {
				return;
			}
			setOpened(false);
		};

		document.addEventListener('click', clickEventListener);

		return function cleanup() {
			document.removeEventListener('click', clickEventListener);
		}
	}, []);

	useEffect(() => {
		let dropdownMenuElement = element.current.querySelector('.dropdown-menu');
		let position = dropdownMenuElement.getBoundingClientRect();
		if ( position.left < 0 ) {
			dropdownMenuElement.style.left = 0;
			dropdownMenuElement.style.right = 'auto';
		} else {
			dropdownMenuElement.style.left = 'auto';
			dropdownMenuElement.style.right = 0;
		}
	}, [opened]);

	// Prepare period rows
	let periodsElements = [];
	for(let i in periods) {
		if ( i === 'custom' ) continue;

		let title = periods[i];
		periodsElements.push(<li key={i}><a className="dropdown-item" href="." onClick={(e) => setPeriodClick(e, i)}>{title}</a></li>)
	}

	let custom_selected = 'Rango';
	if ( period === 'custom' && range && range.from ) {
		custom_selected = moment(range.from).format('DD-MM-YYYY') + ' al ' + moment(range.to ?? range.from).format('DD-MM-YYYY');
	}

	return (
		<DateDropdownStyled className="date-dropdown" color={props.color} ref={element}>
			<div className="dropdown d-inline-block ms-auto">
				<button className="btn btn-sm" type="button" onClick={toggleDropdown}>
					 { period !== 'custom' ? periods[period] : custom_selected } <small><i className="bi bi-caret-down-fill"></i></small>
				</button>
				<div className={'dropdown-menu ' + (opened ? 'show ' : '') + (props.alignment ?? 'end ')} onClick={(e) => e.stopPropagation()}>
					<DropdownMenuInner>
						<DropdownOptions>
							{ periodsElements }
						</DropdownOptions>
						<DayPickerWrapper>
							<DayPicker 
								locale={es}
								mode="range"
								selected={range}
								onSelect={(rng) => {
									setPeriod(null); // Needed because if exists global selector sending dates to this selector, we need to reset period to avoid weird things
									setRange(rng);
								}}
							/>
							<div className="text-center">
								<button 
									className="btn btn-sm btn-primary text-white" 
									style={{width: '150px'}}
									onClick={(e) => setPeriodClick(null, 'custom_' + moment(range.from).format('YYYY-MM-DD') + '_' + moment(range.to ?? range.from).format('YYYY-MM-DD'))}
									disabled={!range?.from}
								>
									Aceptar
								</button>	
							</div>
						</DayPickerWrapper>
					</DropdownMenuInner>
				</div>
			</div>
		</DateDropdownStyled>
	);
}