import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { loader, assets } from 'helpers/generic';
import readXlsxFile from 'read-excel-file'
import { toast } from "react-toastify";

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	.modal {
		position: relative;
	}

	#loader {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		width: 100%;
		height: 100%;
		user-select: none;

		img {
			width: 200px !important;
			height: auto !important;
			user-select: none;
		}
	}
`;

const FileWrapper = styled.div`
	width: 100%;
	padding: 20px;
	background: var(--bs-light);
	border: 1px solid var(--bs-gray-300);
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	input[type=file] {
		visibility: hidden;
		height: 0;
	}
`;

const Table = styled.table`
	font-size: 12px;
	font-weight: normal;

	td {
		&.with-errors {
			background: var(--bs-danger);
			color: white;

			.error {
				color: yellow;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function ImportExcelModal(props) {
	const modalRef = useRef(null);
	const fileRef = useRef(null);

	const closeCallback = props.closeCallback;

	const [file, setFile] = useState(null);
	const [stations, setStations] = useState(null);
	const [stationsDetailed, setStationsDetailed] = useState(null);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		const loadStations = async () => {
			await axios.get('/api/stations/list', {
				params: {
					no_paginate: true
				},
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setStationsDetailed([...response.data]);
				setStations([...response.data.map(el => parseInt(el.octan_id))]);	
			}).catch((error) => {			
				if ( axios.isCancel(error) ) return;
			});	
		}
		loadStations();
	}, []);

	const loadFile = async (file) => {
		// Reset
		setFile(null);
		setData([]);

		// Check if exists the file
        if ( !file ) return false;

		// Read file
		readXlsxFile(file).then((rows) => {
			let toValidate = [...rows];

			// Validate 
			let errors = {};
			toValidate.forEach((row, rowIdx) => {
				// Column 0 - Date
				let checkDate = Date.parse(row[0] ?? undefined);
				if ( !row[0] || isNaN(checkDate) ) errors[rowIdx + '-' + 0] = 'Formato de fecha no válido. Formato válido: dd/mm/yyyy';
				else { // Sanitize
					toValidate[rowIdx][0] = moment(checkDate).format('DD/MM/YYYY');
				}

				// Column 1 - Station code
				let stationExists = stations?.indexOf(parseInt(row[1])) !== -1;
				if ( !row[1] || !stationExists ) errors[rowIdx + '-' + 1] = 'No existe una estación con ese código';				

				// Column 2 - Do nothing

				// Column 3 - Hour interval
				let validFormat = row[3].match(/^(([0-1]?[0-9]|2[0-3]):[0-5][0-9])-(([0-1]?[0-9]|2[0-3]):[0-5][0-9])$/g);
				if ( !row[3] || !validFormat ) errors[rowIdx + '-' + 3] = 'Formato de intervalo no válido. Formato válido: hh:mm-hh:mm';				
				
				// Column 4 - Number decimal
				if ( row[4] === undefined || isNaN(row[4]) ) errors[rowIdx + '-' + 4] = 'Introduce un importe válido';				

				// Column 5 - Number decimal
				if ( row[5] === undefined || isNaN(row[5]) ) errors[rowIdx + '-' + 5] = 'Introduce un importe válido';				

				// Column 6 - Empty or string max 100
				if ( row[6] && row[6].length > 100 ) errors[rowIdx + '-' + 6] = 'Tamaño máximo 100 carácteres';				

				// Column 7 - Empty or string max 100
				if ( row[7] && row[7].length > 100 ) errors[rowIdx + '-' + 7] = 'Tamaño máximo 100 carácteres';				
			
			})
			setErrors(errors);
			
			// Set data
			setData(toValidate);

			// Set file
			setFile(file);
		}).catch((error) => {
			setFile(null);
			setData([]);
			toast.error('Formato de archivo no válido')
			return;
		});	
	}


	const saveData = async () => {
		setLoading(true);

		// Prepare data
		let dataPrepared = [];
		data.forEach((el, idx) => {
			let init_end_split = el[3].split('-');
			let date = moment(el[0], 'DD/MM/YYYY').format('YYYY-MM-DD');
			// let station_id = stationsDetailed.filter(fEl => parseInt(fEl.octan_id) === parseInt(el[1]))[0].id;
			
			dataPrepared.push({
				'station_id'			: el[1],
				'date'					: date,
				'hour_expected_init' 	: init_end_split[0],
				'hour_expected_end' 	: init_end_split[1],
				'diesel_liters' 		: el[4],
				'sp95_liters' 			: el[5],
				'carrier' 				: el[6],
				'carrier_code' 			: el[7]
			});
		});

		document.activeElement.blur();

		await axios.post('api/tankertrucks/bulk-add', {tankertrucks: dataPrepared}, {
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			toast.success('Se han guardado los datos');
			
			// Hide modal
			Modal.getInstance(modalRef.current).hide();
			props.closeCallback();
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
			toast.error('Ha ocurrido un error al guardar');
		});	

		setLoading(false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			{ loading && loader }
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Importar Excel</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						{ stations?.length > 0 &&
							<FileWrapper onClick={() => {fileRef.current.value = null; fileRef.current.click();}}>
								{ file === null ?
									<div>Pulsa para seleccionar el fichero</div>
									:
									<div className="text-center">{file.name}</div>
								}
								<input type="file" ref={fileRef} onChange={(e) => loadFile(e.target.files[0])} />
							</FileWrapper>
						}
						{ data.length > 0 && 
							<Table className="table table-sm table-bordered mt-3">
								<tbody>
									{data && data.map((el, idx) => {
										return (
											<tr key={idx}>
												{el.map((el2, idx2) => {
													return (
														<td key={idx2} className={errors[idx+'-'+idx2] ? 'with-errors' : ''}>
															{el2}
															{errors[idx+'-'+idx2] &&
																<div className="error">{errors[idx+'-'+idx2]}</div>
															}
														</td>
													);
												})}
											</tr>
										)
									})}
								</tbody>
							</Table>
						}
					</div>
					<div className="modal-footer">
						<button className="btn btn-sm btn-link" onClick={() => window.open(assets('/files/ejemplo_excel_cisternas.xlsx'))}>Ejemplo</button>
						<button type="button" className={'btn btn-sm btn-plenoil-primary text-white'} onClick={() => saveData()} disabled={!data.length || Object.keys(errors).length > 0 }>Guardar datos</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}