import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';

const TankStyled = styled.div`
	--border-radius: 10px;

	display: flex;
	align-items: center;

	.temperature {
		width: 20%;
		justify-content: center;
	}

	.graphic {
		width: ${props => props.width ?? 80}%;
		display: block;

		.cilinder {
			--cilinder-border-radius: 17.5px;

			display: flex;
			border-radius: var(--cilinder-border-radius);
			width: 100%;
			height: 30px;
			overflow: hidden;
			padding: 2px;
			color: white;		

			.percent {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border-top-left-radius: calc(var(--cilinder-border-radius));
				border-bottom-left-radius: calc(var(--cilinder-border-radius));
				text-align: center;
				font-size: 16px;	
				min-width: 30px;				

				&.greater_than_95 {
					border-radius: calc(var(--cilinder-border-radius));
				}
			}

			.dark {
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: end;
				font-size: 10px;	
				min-width: 0;		

				span {
					transform: translateX(-5px);
				}		
			}
		}

		.price {
			display: inline-flex;
			border-bottom-left-radius: 15px;
			border-bottom-right-radius: 15px;
			padding: 0px 15px;
		}

		&.diesel {

			.cilinder {
				background: #231f20;

				.percent {
					background: #8b8c8e;
				}

				.dark {
					background: #231f20;
				}
			}

			.price {
				background: #231f20;
				color: #b2b2b2;
			}
		}

		&.splomo {
			.cilinder {
				background: #007b61;

				.percent {
					background: #65b6a5;
				}

				.dark {
					background: #027a61;
				}
			}

			.price {
				background: #007a63;
				color: #90cab9;
			}
		}
	}

	@media (max-width: 768px) {
		.temperature {
			display: none;
		}

		.graphic {
			width: 100% !important;
		}

		&.diesel {
			border-bottom-left-radius: var(--border-radius) !important;
		}
		
		&.splomo {
			border-bottom-right-radius: var(--border-radius);
		}
	}
`;

export default function Tank(props) {


	return (
		<TankStyled className={'tank ' + props.className} width={props.width}>
			{ (props.temperature && props.temperaturePosition === 'left') &&
				<div className="temperature">{ numeral(props.temperature).format('0,0[.]0') }º</div>
			}
			<div className={'graphic ' + (props.type)}>
				<div className="cilinder">
					<div className={'percent ' + (props.percent > 95 ? 'greater_than_95' : '')} style={{width: props.percent + '%'}}>
						{ (props.percent ?? 0).toFixed(0) }%
					</div>
					<div className="dark" style={{width: (100-props.percent) + '%'}}>
						<span>{ numeral(props.available).format('0,0[.]00') }</span>
					</div>
				</div>
				{ props.price &&
					<div className="price">
						{ numeral(props.price).format('0,0[.]000') } €
					</div>
				}
			</div>
			{ (props.temperature && props.temperaturePosition === 'right') &&
				<div className="temperature">{ numeral(props.temperature).format('0,0[.]0') }º</div>
			}
		</TankStyled>
	);
}