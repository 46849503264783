import axios from 'axios';

export default class Chat {

	static axiosCancelToken = axios.CancelToken.source();
	
	static getStations = (opened = false, search = '') => {
		this.newAxiosCancelToken();

		return axios.get('api/chat/get-stations', {
			params: {
				opened_conversations: opened,
				search: search
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static sendMessage = (station_id, channel, message) => {
		this.newAxiosCancelToken();

		return axios.post('api/chat/send', {
			station_id: station_id,
			channel: channel,
			message: message
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static uploadImage = (station_id, channel, blob) => {
		this.newAxiosCancelToken();

		let data = new FormData();
		data.append('station_id', station_id);
		data.append('channel', channel);
		data.append('image', blob);

		return axios.post('api/chat/send', data, {
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data.errors;
		});
	}

	static getMessages = (station_id, channel, from) => {
		this.newAxiosCancelToken();

		return axios.get('api/chat/messages', {
			params: {
				station_id: station_id,
				channel: channel,
				from: from
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}
	
	static downloadImage = (message_id, size) => {
		this.newAxiosCancelToken();

		return axios.get('api/chat/view-image', {
			params: {
				id: message_id,
				size: size
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static readMessage = (message_id) => {
		this.newAxiosCancelToken();

		return axios.post('api/chat/read', {
			id: message_id
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		});
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}