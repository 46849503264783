import React, { useEffect, useState, useRef, memo } from "react";
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';
import { loader } from 'helpers/generic';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	.modal {
		position: relative;
	}

	#loader {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9999;
		width: 100% !important;
		height: 100% !important;
		user-select: none;

		img {
			width: 200px !important;
			height: auto !important;
			user-select: none;
		}
	}
`;

let axiosCancelToken = null;

const ImportExcelModal = memo((props) => {
	const modalRef = useRef(null);

	const closeCallback = props.closeCallback;

	const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
	const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);


	const getExcel = async () => {
		setLoading(true);

		document.activeElement.blur();

		await axios.post('/api/tankertrucks/download-excel', {
			date_from: dateFrom,
			date_to: dateTo
		}, {
			responseType: 'blob',
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
		    const link = document.createElement('a');
		    link.href = url;
		    link.setAttribute('download', 'cisternas_' + dateFrom + '_' + dateTo + '.xlsx'); //or any other extension
		    document.body.appendChild(link);
		    link.click();
		    document.body.removeChild(link);
	  	}).catch((error) => {			
			if ( axios.isCancel(error) ) return;
		});	

		setLoading(false);
	}

	console.log('render')

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			{ loading && loader }
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Exportar Excel</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-6">
								<div className="mb-2">
									<label>Desde</label>
									<input type="date" className="form-control form-control-sm" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-2">
									<label>Hasta</label>
									<input type="date" className="form-control form-control-sm" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className={'btn btn-sm btn-plenoil-primary text-white'} tabIndex="-1" onClick={() => getExcel()} disabled={!dateFrom || !dateTo}>Exportar</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}, (a, b) => {
	return true;
});

export default ImportExcelModal;