import { useEffect, useState } from 'react';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as StationsService } from 'services/Stations';
import { default as SuppliesService } from 'services/Supplies';
 
const Table = styled.table`
	td, th {
		
		&:nth-child(1) {
			
		}
		
		&:nth-child(2) {
			width: 100px;
		}
		
		&:nth-child(3) {
			width: 150px;
		}
	}
`;

export default function SuppliesStationsWashSwitch() {
	let [stations, setStations] = useState([]);

	useEffect(() => {
		const getStations = async () => {
			let stations = await StationsService.getList({no_paginate: true});
			setStations(stations);
		}
		getStations();

		return function cleanup() {
			SuppliesService.cancel();
        }
	}, []);

	const toggleStationHasWash = async (station, status) => {
		let result = await SuppliesService.toggleStationHasWash(station.id, status);
		if ( result ) {
			let new_stations = stations.map((el) => {
				if ( el.id === station.id ) {
					el.has_wash = result.has_wash;
				}
				return el;
			});
			setStations(new_stations);
		}
	}

	return (
		<LoggedLayout>
			<div className="col-md-12 mb-3">
				<h4 className="mb-0">Activación de lavaderos</h4>
			</div>

			<div className="col-md-12 mb-3">
				<div className="table-responsive">
					<Table className="table table-bordered bg-white">
						<thead>
							<tr className="bg-plenoil-brown-light text-white">
								<th>Nombre</th>
								<th>Octan ID</th>
								<th>¿Tiene lavadero?</th>
							</tr>
						</thead>
						<tbody>
							{ stations.map((el, idx) => {
								return (
									<tr key={idx}>
										<td>{el.name}</td>
										<td>{el.octan_id}</td>
										<td>
											<div className="form-check form-switch">
												<input className="form-check-input" type="checkbox" checked={el.has_wash} onChange={(e) => toggleStationHasWash(el, e.target.checked)} />
											</div>
										</td>
									</tr>
								)
							}) }
							{ !stations.length && <tr key="empty"><td colSpan="100%">No hay estaciones</td></tr> }
						</tbody>
					</Table>
				</div>
			</div>
		</LoggedLayout>
	);
}


