import React, { useEffect, useState, useRef, memo } from "react";
import axios from 'axios';
import styled from 'styled-components';
import { Modal } from 'bootstrap/dist/js/bootstrap';

const ModalStyled = styled.div`
	background: rgba(0, 0, 0, 0.4);

	&.modal {

		label {
			font-size: 15px;
			line-height: 15px;
		}

		small {
			font-size: 12px;
			line-height: 12px;
			color: var(--plenoil-blue-light);
		}
	}

	#table-periods {
		td {
			vertical-align: top;
		}
	}
`;

let axiosCancelToken = null;

const GlobalConfigModal = memo((props) => {
	const modalRef = useRef(null);

	const closeCallback = props.closeCallback;

	const [data, setData] = useState({
		enabled: false,
		periods: []
	});
	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		const modal = new Modal(modalRef.current, {backdrop: false});

		const hiddenEvent = (e) => {
           	modal._element.removeEventListener('hidden.bs.modal', hiddenEvent);
           	modal._element.removeEventListener('shown.bs.modal', hiddenEvent);
			modal.dispose();
			closeCallback();

			// Fix, because bootstrap removes scroll and add padding on modal opened
			document.body.style.overflow = 'auto'; 
			document.body.style.paddingRight = '0';
		}

		modal._element.addEventListener('hidden.bs.modal', hiddenEvent);

		modal.show();

		// Fix, because bootstrap removes scroll and add padding on modal opened
		document.body.style.overflow = 'auto'; 
		document.body.style.paddingRight = '0';
		
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, [closeCallback]);

	useEffect(() => {
		const getData = async () => {
			setErrors({});
			setLoading(true);
			await axios.get('/api/probes-warnings/get-global-config', {
				cancelToken: axiosCancelToken.token
			}).then((response) => {
				setData(response.data);
			}).catch((error) => {
				if ( axios.isCancel(error) ) return;
			});
			setLoading(false);
		}
		getData();
	}, []);

	const closeModal = () => {
		// Hide modal
		Modal.getInstance(modalRef.current).hide();
		props.closeCallback();
	}

	const setDataField = (field, value) => {
		setData((prev) => ({...prev, [field]: value}));
	}

	const saveData = async () => {
		setErrors({});
		setLoading(true);

		await axios.post('/api/probes-warnings/save-global-config', data, {
	  		cancelToken: axiosCancelToken.token
	  	}).then((response) => {
			closeModal();
	  	}).catch((error) => {
	  		if ( axios.isCancel(error) ) return;
	  		setErrors(error.response.data.errors);
	  	});
		
		setLoading(false);
	}

	return (
		<ModalStyled className="modal" tabIndex="-1" ref={modalRef}>
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Configuración</h5>
						<button type="button" className="btn-close" onClick={closeModal}></button>
					</div>
					<div className="modal-body">
						<div className="row">
							<div className="col-md-12">
								<div className="mb-3">
									<label>Email</label>
									<input type="text" className="form-control form-control-sm" value={data.email ?? ''} onChange={(e) => setDataField('email', e.target.value)} />
									<small>Dirección donde se recibirán los avisos</small>
									{ errors?.email &&
										<div className="invalid-feedback d-block">{ errors['email'][0] ?? '' }</div>
									}
								</div>

								<div className="mb-3">
									<label>Encendido</label>
									<div className="form-check form-switch">
										<input className="form-check-input" type="checkbox" checked={data.enabled ?? false} onChange={(e) => setDataField('enabled', e.target.checked ? true : false)} />
									</div>
									<small>Activar / desactivar avisos</small>
									{ errors?.enabled &&
										<div className="invalid-feedback d-block">{ errors['enabled'][0] ?? '' }</div>
									}
								</div>

								<div className="mb-0">
									<label>Porcentaje de margen</label>
									<input type="number" className="form-control form-control-sm" value={data.percent ?? ''} onChange={(e) => setDataField('percent', e.target.value)} />
									<small>Define el porcentaje mínimo para avisar</small>
									{ errors?.percent &&
										<div className="invalid-feedback d-block">{ errors['percent'][0] ?? '' }</div>
									}
								</div>
							</div>
						</div>
					</div>
					<div className="modal-footer">
						<button className="btn btn-sm btn-plenoil-primary" disabled={loading} onClick={() => loading ? null : saveData()}>{loading ? 'Guardando...' : 'Guardar'}</button>
					</div>
				</div>
			</div>
		</ModalStyled>
	);
}, (a, b) => {
	return true;
});

export default GlobalConfigModal;