import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { default as PricesService } from 'services/Prices';
import { hasCapability } from 'helpers/user';

const CheckButtonStyled = styled.div`
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 15px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: ${props => props.status === null ? '1px solid var(--bs-gray-500)' : ''};
    background: ${props => props.status === 1 ? 'var(--plenoil-blue)' : ''} ${props => props.status === 0 ? 'var(--plenoil-red)' : ''};
    color: ${props => props.status === 1 ? 'var(--plenoil-yellow)' : ''} ${props => props.status === 0 ? 'white' : ''};
    cursor: pointer;

    &:hover,
    &:focus {
        outline: 10px solid var(--bs-gray-400);
        .status-dropdown {
            display: block;
        }
    }

    .status-dropdown {
        display: none;
        z-index: 2;
        position: absolute;
        top: -35px;
        left: 0;
        width: 110px;
        height: 45px;
        border-radius: 3px;
        background: white;
        overflow: hidden;
        box-shadow: 0px 0px 5px var(--bs-gray-500);

        button {
            display: block;
            width: 100%;
            font-size: 11px;
            border: 0;
            padding: 0;
            color: white;
            height: 15px;
            text-align: left;
            padding-left: 5px;

            &:nth-child(1) {
                background: var(--plenoil-blue);
            }

            &:nth-child(2) {
                background: var(--plenoil-red);
            }

            &:nth-child(3) {
                background: var(--bs-light-300);
                color: var(--bs-secondary);
            }

            &:hover {
                color: var(--plenoil-yellow);
            }
        }
    }
`;

export default function CheckButton(props) {
    const checkId = props.checkId;
    const status = props.status;
    const type = props.type;
    const forceReload = props.forceReload;

    const updateStatus = async (status) => {
        let result = await PricesService.updateCheckStatus(checkId, type, status);
        if ( !result.status ) {
            toast.error('Error al cambiar el estado');
            return;
        }

        forceReload();
    }

	return (
		<CheckButtonStyled status={status}>
            { status === 1 && <i className="bi bi-check"></i> }
            { status === 0 && <i className="bi bi-x"></i> }

            { hasCapability('prices', 'edit') &&
                <div className="status-dropdown">
                    <button onClick={() => updateStatus(true)}>Cambiar a correcto</button>
                    <button onClick={() => updateStatus(false)}>Cambiar a incorrecto</button>
                    <button onClick={() => updateStatus(null)}>Cambiar a pendiente</button>
                </div>
            }
        </CheckButtonStyled>
	);
}